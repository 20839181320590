<template>
<div>
  <template 
    v-if="_row.trp_approve_status=='Y'"
  >
    <p
      v-if="['D','P'].indexOf(_row.trp_inherent_status)>-1"
      class="status_penilaian text-success f-600"
    >
      Inheren 
      <router-link
        v-if="
          canEdit && 
          !moduleRole('ViewOnly') && 
          moduleRole('isPenaksir') &&
          !(_row.PenaksiranDid||false)
        "
        :to="{name:$route.name, params:{pageSlug:'inheren', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Lakukan Penilaian Inheren"
        >
          <i class="icon-note"></i>
        </div>
      </router-link>
    </p>
    <p
      v-else-if="_row.trp_inherent_status=='Y' && _row.trp_doc_evidence_status=='N'"
      class="status_penilaian text-success f-600"
    >
      Dok Pengendalian 
      <router-link
        v-if="canEdit && !moduleRole('ViewOnly') && moduleRole('isRiskOfficer')"
        :to="{name:$route.name, params:{pageSlug:'upload-evidence', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Upload Dokumen Pengendalian"
        >
          <i class="icon-cloud-upload"></i>
        </div>
      </router-link>
    </p>
    <p
      v-else-if="
        ['D','P'].indexOf(_row.trp_current_status)>-1 && _row.trp_doc_evidence_status=='Y'
      "
      class="status_penilaian text-info f-600"
    >
      Current 
      <router-link
        v-if="
          canEdit && 
          !moduleRole('ViewOnly') && 
          moduleRole('isPenaksir') &&
          !(_row.PenaksiranDid||false)
        "
        :to="{name:$route.name, params:{pageSlug:'current', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Isi Nilai Current"
        >
          <i class="icon-note"></i>
        </div>
      </router-link>
    </p>
    <p
      v-else-if="
        _row.trp_tindakan_mitigasi_status=='N'
      "
      class="status_penilaian text-warning f-600"
    >
      Tindakan Mitigasi 
      <router-link
        v-if="canEdit && !moduleRole('ViewOnly') && moduleRole('isRiskOfficer')"
        :to="{name:$route.name, params:{pageSlug:'tindakan-mitigasi', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Buat Tindakan Mitigasi"
        >
          <i class="icon-note"></i>
        </div>
      </router-link>
    </p>
    <p
      v-else-if="
        ['N','P'].indexOf(_row.trp_residual_status)>-1
      "
      class="status_penilaian text-danger f-600"
    >
      Residual 
      <router-link
        v-if="
          canEdit && 
          !moduleRole('ViewOnly') && 
          moduleRole('isPenaksir') &&
          !(_row.PenaksiranDid||false)
        "
        :to="{name:$route.name, params:{pageSlug:'residual', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Isi Nilai Residual"
        >
          <i class="icon-note"></i>
        </div>
      </router-link>
    </p>
    <p
      v-else-if="
        _row.trp_jadwal_mitigasi_status=='N'
      "
      class="status_penilaian text-danger f-600"
    >
      Jadwal Mitigasi 
      <router-link
        v-if="canEdit && !moduleRole('ViewOnly') && moduleRole('isRiskOfficer')"
        :to="{name:$route.name, params:{pageSlug:'jadwal-mitigasi', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Buat Jadwal Mitigasi"
        >
          <i class="icon-note"></i>
        </div>
      </router-link>
    </p>
    <!-- <p
      v-else-if="
        _row.trp_hasil_mitigasi=='N' && _row.trp_jadwal_mitigasi_status=='Y'
      "
      class="status_penilaian text-warning f-600"
    >
      Mitigasi 
      <router-link
        v-if="!moduleRole('ViewOnly') && moduleRole('isRiskOfficer')"
        :to="{name:$route.name, params:{pageSlug:'hasil-mitigasi', pageId:_row.trp_id}}" 
      >
        <div 
          v-b-tooltip.hover
          title="Isi Hasil Mitigasi"
        >
          <i class="icon-note"></i>
        </div>
      </router-link>
    </p> -->
    <p
      v-else-if="
        _row.trp_selesai_date
      "
      class="status_penilaian text-purple f-600"
    >
      Selesai
    </p>
  </template>
  <p 
    v-else
    class="status_penilaian f-600"
  >
    Belum Ada
  </p>

  <p 
    v-if="_row.trp_locked_periode=='Y'"
    class="status_penilaian f-600 text-info"
  >
    Locked
  </p>
</div>
</template>

<script>
import Global from '@/libs/Global'
export default {
  extends:Global,
  props:{
    _row:Object,
    periodeCurrent:Object,
  },
  computed:{
    canEdit(){
      return this.periodeCurrent.tahun == this._row.trp_tahun &&
        this.periodeCurrent.bulan == this._row.trp_bulan &&
        this.periodeCurrent.status=='ONGOING' && this._row.trp_locked_periode=='N'
    }
  }
}
</script>