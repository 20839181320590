<template>
<div>
  <b-card v-if="_row.trp_id" title="Buat Tindakan Mitigasi">
    <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
      <form @submit.prevent="handleSubmit(doSubmitForm)">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ _row.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="_row.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p>{{_row.muk_code.toUpperCase()}} - {{_row.muk_level.toUpperCase()}} - {{_row.muk_description.toUpperCase()}}</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="card b-all shadow-none mt-3">
          <div :class="{'table-responsive':isMobile}">
            <table class="table m-b-0  table-bordered">
              <thead>
                <tr class="full-color-table full-purple-table">
                  <th> # </th>
                  <th> Nama Risiko </th>
                  <th width="10%"> Jumlah Pengendalian </th>
                  <th width="10%"> Jumlah Tindakan </th>
                  <th width="20%"> Aksi </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(val,key) in risiko"
                  :key="key"
                >
                  <td>{{ key+1 }}.</td>
                  <td>{{ val.risiko_name }}</td>
                  <td>{{ val.kendali.length }}</td>
                  <td>
                    <span :class="{'text-danger':!DataSubRisiko(val.mitigasi, '').length}">
                      {{ DataSubRisiko(val.mitigasi, '').length }}
                    </span>
                  </td>
                  <td>
                    <b-button 
                      v-if="DataSubRisiko(val.mitigasi, '').length"
                      variant="outline-warning"
                      @click="doOpenModal(val, 'mitigasi')"
                    >
                      Ubah Tindakan
                    </b-button>
                    <b-button 
                      v-else
                      variant="outline-danger"
                      @click="doOpenModal(val, 'mitigasi')"
                    >
                      Butuh Tindakan
                    </b-button>
                    <VValidate 
                      :name="'Risiko ke-'+(key+1)"
                      :value="(DataSubRisiko(val.mitigasi, '').length||'')"
                      rules="required" 
                      class="d-block"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </form>
    </ValidationObserver>

    <template #footer>
      <b-row class="mt-4">
        <b-col md=6>
          <b-button
            class="btn waves-effect btn-rounded waves-light btn-outline-dark"
            :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
          >
            Kembali
          </b-button>
        </b-col>
        <b-col md=6>
          <div align="right">
            <b-button
              class="btn btn-rounded  btn-info"
              @click="doSubmitForm()"
            >
              Konfirmasi Penaksiran
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
  
  <b-modal 
    id="RisikoModal" 
    :title="modalTitle"
    :dialog-class="(modalTab=='pilih_risiko'?'modal_xl_custom':'')"
    size="xl"
    ok-variant="info"
    ok-title="Simpan" 
    cancel-title="Batal"
    :ok-only="modalTab!='add_risiko'"
    :hide-header-close="modalTab=='add_risiko'"
    :busy="loadingOverlay"
    no-close-on-backdrop
    no-close-on-esc
    @ok="doOKModal"
    @cancel="doCancelModal"
    @close="doCancelModal"
  >
    <b-overlay :show="loadingOverlay" rounded="sm">
      <template v-if="Object.keys(modal).length">
        <template v-if="modalTab=='add_risiko'">
          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm25">
            <form @submit.prevent="handleSubmit(doAddRisiko)">
              <b-form-group
                label="Kategori *"
              >
                <VSelect 
                  v-model="input.category" 
                  placeholder="-- Pilih Satu --"
                  :options="mrCategory" 
                  :reduce="v=>v.value" 
                />
                <VValidate 
                  name="Kategori" 
                  :value="input.category||''"
                  rules="required" 
                />
              </b-form-group>
              <b-form-group
                label="Deskripsi *"
              >
                <b-input
                  v-model="input.name"
                />
                <VValidate 
                  name="Deskripsi" 
                  :value="input.name||''"
                  rules="required" 
                />
              </b-form-group>
              <b-form-group
                v-if="tabRisiko=='mitigasi'"
                label="Terkait *"
              >
                <VSelect 
                  v-model="input.terkait" 
                  placeholder="-- Pilih Satu --"
                  :options="mrTerkait" 
                  :reduce="v=>v.value" 
                />
                <VValidate 
                  name="Terkait" 
                  :value="input.terkait||''"
                  rules="required" 
                />
              </b-form-group>
            </form>
          </ValidationObserver>
        </template>
        <template v-else-if="modalTab=='pustaka_risiko'">
          <b-card no-body>
            <b-card-header class="bg-white pl-0 pr-0">
              <b-row>
                <b-col md=7>
                  <b-button 
                    variant="info"
                    @click="modalTab='add_risiko'"
                  >
                    <i class="fa fa-plus-circle"></i> Tambah Data Baru
                  </b-button>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-input 
                      v-model="MTRSearch.pustaka_risiko"
                      placeholder="Ketik untuk mencari ..."
                    />
                    <b-input-group-append>
                      <b-button
                        variant="info"
                        size="sm"
                        class="btn-icon"
                      >
                        <i class="icon-magnifier"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card-header>
            <b-table
              id="my-table4"
              :fields="MTRField.pustaka_risiko"
              :items="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N'])"
              :per-page="MTRPerPage.pustaka_risiko"
              :current-page="MTRCurrentPage.pustaka_risiko"
              small
              show-empty
              thead-tr-class="full-color-table full-purple-table"
              bordered
              responsive
            >
              <template #empty>
                <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
              </template>
              <template #cell(number)="data">
                {{ data.index+1 }}
              </template>
              <template #cell(terkait)="data">
                {{ data.value=='sebab'?'kemungkinan':(data.value||'-') }}
              </template>
              <template #cell(aksi)="data">
                <template v-if="data.item.is_selected=='Y'">
                  <span class="label label-purple">Telah dipilih</span>
                  <button 
                    type="button" 
                    style="vertical-align:center" 
                    class="btn btn-secondary ml5"
                    @click="doUnSelectRisiko(data,tabRisiko)"
                  >
                    <i class="ti-trash"></i>
                  </button>
                </template>
                <template v-else>
                  <button 
                    type="button" 
                    style="vertical-align:center" 
                    class="btn btn-secondary"
                    @click="doSelectRisiko(data,tabRisiko)"
                  >
                    <i class="ti-plus"></i> Pilih Data
                  </button>
                </template>
              </template>
            </b-table>
            <b-pagination
              v-if="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N']).length >= MTRPerPage.pustaka_risiko"
              v-model="MTRCurrentPage.pustaka_risiko"
              :total-rows="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N']).length"
              :per-page="MTRPerPage.pustaka_risiko"
              aria-controls="my-table4"
            ></b-pagination>
          </b-card>
        </template>
        <template v-else>
          <div class="wrap_info_preview">
            <b-row>
              <b-col md=2>
                <div class="wrap_preview_prof">
                  <h3>Periode</h3>
                  <p v-if="_row.trp_is_periode=='triwulan'"> 
                    Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
                  </p>
                  <p v-else>
                    {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
                  </p>
                </div>
              </b-col>
              <b-col md=5>
                <div class="wrap_preview_prof">
                  <h3>Kantor</h3>
                  <p> {{ _row.mo_code }} - {{ (_row.mo_level||"").toUpperCase() }} - {{ _row.mo_name }}</p>
                </div>
              </b-col>
              <b-col md=5>
                <div class="wrap_preview_prof">
                  <h3>Unit Kerja</h3>
                  <p> {{ _row.muk_code }} - {{ (_row.muk_level||"").toUpperCase() }} - {{ _row.muk_description }}</p>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <div class="wrap_preview_prof">
                  <h3>Nama Risiko</h3>
                  <p> {{modal.risiko_name}}</p>
                </div>
              </b-col>
            </b-row>
          </div>
          
          <VTab :justify="true" class="mt-3">
            <template slot="link">
              <VTabLink :step.sync="tabRisiko" option="mitigasi">Tindakan Mitigasi</VTabLink>
              <VTabLink :step.sync="tabRisiko" option="kendali">Pengendalian</VTabLink>
            </template>
            <template slot="content">
              <VTabContent :step.sync="tabRisiko" option="mitigasi">
                <b-card no-body>
                  <b-card-header class="bg-white pl-0 pr-0">
                    <b-button-group class="mb-3">
                      <b-button 
                        variant="warning"
                        @click="modalTab='pustaka_risiko'"
                      >
                        <i class="icon-list"></i> Tambah Dari Pustaka
                      </b-button>
                    </b-button-group>
                    <b-row>
                      <b-col md=2>
                        <b-input-group v-if="DataSubRisiko(modal.mitigasi||[], 'mitigasi').length > MTRPerPage.mitigasi">
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.mitigasi"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col md=5></b-col>
                      <b-col>
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.mitigasi"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3A"
                    :fields="MTRField.mitigasi"
                    :items="DataSubRisiko(modal.mitigasi||[], 'mitigasi')"
                    :per-page="MTRPerPage.mitigasi"
                    :current-page="MTRCurrentPage.mitigasi"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(terkait)="data">
                      {{ data.value=='sebab'?'kemungkinan':(data.value||'-') }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'mitigasi')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="DataSubRisiko(modal.mitigasi||[], 'mitigasi').length >= MTRPerPage.mitigasi"
                    v-model="MTRCurrentPage.mitigasi"
                    :total-rows="DataSubRisiko(modal.mitigasi||[], 'mitigasi').length"
                    :per-page="MTRPerPage.mitigasi"
                    aria-controls="my-table3A"
                  ></b-pagination>
                </b-card>
              </VTabContent>
              <VTabContent :step.sync="tabRisiko" option="kendali">
                <b-card no-body>
                  <b-card-header class="bg-white pl-0 pr-0">
                    <b-row>
                      <b-col md=2>
                        <b-input-group v-if="DataSubRisiko(modal.kendali||[], 'kendali').length > MTRPerPage.kendali">
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.kendali"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col md=5></b-col>
                      <b-col>
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.kendali"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3A"
                    :fields="MTRField.kendali"
                    :items="DataSubRisiko(modal.kendali||[], 'kendali')"
                    :per-page="MTRPerPage.kendali"
                    :current-page="MTRCurrentPage.kendali"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(terkait)="data">
                      {{ data.value=='sebab'?'kemungkinan':(data.value||'-') }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="DataSubRisiko(modal.kendali||[], 'kendali').length >= MTRPerPage.kendali"
                    v-model="MTRCurrentPage.kendali"
                    :total-rows="DataSubRisiko(modal.kendali||[], 'kendali').length"
                    :per-page="MTRPerPage.kendali"
                    aria-controls="my-table3A"
                  ></b-pagination>
                </b-card>
              </VTabContent>
            </template>
          </VTab>
        </template>
      </template>
    </b-overlay>
  </b-modal>
</div>
</template>

<script>
//import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let Swal = global.Swal
export default {
  extends:GlobalVue,
  components:{
    VTab, VTabLink, VTabContent
  },
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      modal:{},
      input:{},
      modalTab:'pilih_risiko',
      tabRisiko:'mitigasi',
      idCreatedByForm:[],
      MTRPerPage:{
        mitigasi:10,
        kendali:10,
        pustaka_risiko:10,
      },
      MTRSearch:{
        mitigasi:"",
        kendali:"",
        pustaka_risiko:"",
      },
      MTRCurrentPage:{
        mitigasi:1,
        kendali:1,
        pustaka_risiko:1,
      },
      MTRField:{
        mitigasi:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          { key:'terkait' },
          'aksi',
        ],
        kendali:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          { key:'terkait' }
        ],
        pustaka_risiko:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          { key:'terkait' },
          'aksi',
        ]
      },
    }
  },
  computed:{
    risiko(){
      return this._post?.risiko||[]
    },
    mrCategory(){
      return this._post?.mrCategory||[]
    },
    mrTerkait(){
      return this._post?.mrTerkait||[]
    },
    modalTitle(){
      if(this.modalTab=='pustaka_risiko') return 'Pilih Risiko'
      else if(this.modalTab=='add_risiko') return 'Tambah Risiko '+this.tabRisiko
      return 'Detail Risiko'
    },
  },
  methods:{
    /* MODAL EVENT */
    doOpenModal(data, tab){
      this.modalTab="pilih_risiko"
      this.$set(this,'modal', data)
      this.$set(this,'tabRisiko', tab)
      this.$bvModal.show('RisikoModal')
    },
    doOKModal(bvModalEvt) {
      if(this.modalTab=='add_risiko'){
        bvModalEvt.preventDefault()
        this.doAddRisiko(this.modal[this.tabRisiko])
      }else if(this.modalTab=='pustaka_risiko'){
        bvModalEvt.preventDefault()
        this.modalTab='pilih_risiko'
      }
    },
    doCancelModal(bvModalEvt) {
      if(['add_risiko','pustaka_risiko'].indexOf(this.modalTab)>-1){
        bvModalEvt.preventDefault()
        this.modalTab='pilih_risiko'
      }else{
        this.$bvModal.hide('RisikoModal')
      }
    },

    /* FILTER TAB RISIKO DATA */
    DataSubRisiko(data, tab, isSelected=['Y']){
      let dataSelected = _.filter(
        data,
        (v)=>{
          return isSelected.indexOf(v.is_selected)>-1
        }
      )
      if(!this.MTRSearch[tab] || !tab){
         return dataSelected
      }else{
        let TSearch = (this.MTRSearch[tab]||"").toLowerCase()
        return _.filter(
          dataSelected, 
          (v)=>{
            return ( 
              (v.nama||"").toLowerCase().includes(TSearch)  ||
              (v.kategori||"").toLowerCase().includes(TSearch) ||
              (v.code||"").toLowerCase().includes(TSearch)
            )
          }
        )
      }
    },

    /* DO EVENT */
    doUnSelectRisiko(data, tab){
      let index = _.indexOf(_.map(this.modal[tab], (v)=>{return v.id}), data.item.id)
      if(this.idCreatedByForm.indexOf(data.item.id)>-1){
        // DELETE IN MASTER TOO
        /* let tabLink = "MrRisikoKendali";

        this.loadingOverlay=true
        Gen.apiRest(
          "/do/"+tabLink,
          {
            data:{
              type:'delete',
              id:data.item.id
            }
          },
          'POST'
        ).then(()=>{
          this.modal[tab][index].is_selected='N'
          this.modal[tab].splice(index, 1)  
          this.idCreatedByForm.splice(this.idCreatedByForm.indexOf(data.item.id), 1)
          this.loadingOverlay=false
        }) */
        this.modal[tab][index].is_selected='N'
      }else{
        // DIRECT UNSELECT
        this.modal[tab][index].is_selected='N'
      }
    },
    doSelectRisiko(data, tab){
      let index = _.indexOf(_.map(this.modal[tab], (v)=>{return v.id}), data.item.id)
      this.modal[tab][index].is_selected='Y'
    },
    doAddRisiko(data){
      // ADD TO MASTER
      let tab = 'MrRisikoKendali'
      let input = this.input
      input['risiko_id']=this.modal.risiko_id
      input['is_active']=1
      input = _.mapKeys(this.input, (_, k)=>{
        return 'mrk_'+k
      })

      this.doSubmit(
        "/do/"+tab, 
        Object.assign(input, {
          type:'add'
        }), 
        (type, resp)=>{
          if(type=='success'){
            data.push(resp.data.row); this.$set(this,'input',{});
            this.idCreatedByForm.push(resp.data.row.id)
            this.modalTab='pilih_risiko'
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        },
        'POST', 'VForm25'
      )
    },

    /** DOSUBMITFORM */
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'DoTindakanMitigasi',
          id: this.pageId,
          risiko:_.clone(this.risiko)
        }, 
        (type, resp)=>{
          if(type=='success'){
            Swal.fire({
              title: "Tindakan Mitigasi Berhasil Dibuat!",
              icon: "success",
              text:'Data dilanjutkan ke Penaksir untuk diisi nilai residual',
              confirmButtonText: 'OK',
              reverseButtons:true,
              allowEscapeKey:false,
              allowEnterKey:false,
              allowOutsideClick:false
            }).then((result) => {
              if(result.value){
                this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
              }
            })
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.card-body{
  padding-bottom:0px !important
}
</style>