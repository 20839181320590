<template>
<div>
  <b-card v-if="_row.trp_id" title="Isi Residual">
    <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
      <form @submit.prevent="handleSubmit(doSubmitForm)">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ _row.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="_row.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p>{{_row.muk_code.toUpperCase()}} - {{_row.muk_level.toUpperCase()}} - {{_row.muk_description.toUpperCase()}}</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          :fields="fields"
          :items="residual"
          small
          show-empty
          class="m-b-0 mt-2 table-hover table-bordered"
          bordered
        >
          <template #empty>
            <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
          </template>
          <template #thead-top>
            <b-tr>
              <b-th 
                colspan="2"
                class="full-color-table full-purple-table"
              />
              <b-th 
                colspan="3"
                class="text-center full-color-table full-danger-table"
              >
                <center>Inheren</center>
              </b-th>
              <b-th 
                colspan="3"
                class="text-center full-color-table full-info-table"
              >
                <center>Current</center>
              </b-th>
              <b-th 
                colspan="3"
                class="text-center full-color-table full-warning-table"
              >
                <center>Residual</center>
              </b-th>
            </b-tr>
          </template>
          <template #cell(number)="data">
            {{data.index+1}}
          </template>
          <template #cell(current_k)="data">
            {{round(data.value)}}
          </template>
          <template #cell(current_d)="data">
            {{round(data.value)}}
          </template>
          <template #cell(current_kd)="data">
            {{round(data.value)}}
          </template>
          <template #cell(k)="data">
            <template v-if="data.item.k_option=='select'">
              <VSelect
                v-model="data.item.k" 
                placeholder="--Pilih Nilai--"
                :options="data.item.k_option_data"
                :clearable="false" 
                :reduce="v=>v.value" 
              />
              <VValidate 
                :name="'K ke-'+(data.index+1)"
                v-model="data.item.k" 
                rules="required" 
              />
            </template>
            <template v-else>
              <b-input 
                :value="round(data.item.k)"
                readonly
              />
            </template>
          </template>
          <template #cell(d)="data">
            <template v-if="data.item.d_option=='select'">
              <VSelect
                v-model="data.item.d" 
                placeholder="--Pilih Nilai--"
                :options="data.item.d_option_data" 
                :clearable="false" 
                :reduce="v=>v.value" 
              />
              <VValidate 
                :name="'D ke-'+(data.index+1)"
                v-model="data.item.d" 
                rules="required" 
              />
            </template>
            <template v-else>
              <b-input 
                :value="round(data.item.d)"
                readonly
              />
            </template>
          </template>
          <template #cell(kd)="data">
            <b-input
              :value="(data.item.k&&data.item.d?data.item.k*data.item.d:'')"
              readonly
            />
          </template>
        </b-table>

      </form>
    </ValidationObserver>

    <template #footer>
      <b-row class="mt-4">
        <b-col md=6>
          <b-button
            class="btn waves-effect btn-rounded waves-light btn-outline-dark"
            :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
          >
            Kembali
          </b-button>
        </b-col>
        <b-col md=6>
          <div align="right">
            <b-button
              class="btn btn-rounded  btn-info"
              @click="doSubmitForm()"
            >
              Konfirmasi Penaksiran
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

let _ = global._
let Swal = global.Swal
export default {
  extends:GlobalVue,
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      fields:[
        {key:'number', label:'#', thClass:'full-color-table full-purple-table v_middle'},
        {key:'risiko_name', label:'Nama Risiko', thClass:'full-color-table full-purple-table v_middle w_200'},
        {key:'inheren_k', label:'K', thClass:'text-center full-color-table full-danger-table '},
        {key:'inheren_d', label:'D', thClass:'text-center full-color-table full-danger-table  '},
        {key:'inheren_kd', label:'K x D', thClass:'text-center full-color-table full-danger-table', thStyle:'width:80px' },
        {key:'current_k', label:'K', thClass:'text-center full-color-table full-info-table '},
        {key:'current_d', label:'D', thClass:'text-center full-color-table full-info-table '},
        {key:'current_kd', label:'K x D', thClass:'text-center full-color-table full-info-table', thStyle:'width:80px' },
        {key:'k', thClass:'text-center full-color-table w_100 full-warning-table', tdClass:"w_100"},
        {key:'d', thClass:'text-center full-color-table w_100 full-warning-table', tdClass:"w_100"},
        {key:'kd', label:'K x D', thClass:'text-center full-color-table full-warning-table w_100' }
      ]
    }
  },
  computed:{
    residual(){
      return this._post?.residual||[]
    },
  },
  methods:{
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'DoResidual',
          id: this.pageId,
          residual:_.clone(this.residual)
        }, 
        (type, resp)=>{
          if(type=='success'){
            if(resp.residual_status=='Y'){
              Swal.fire({
                title: "Nilai Residual Berhasil Diisi!",
                icon: "success",
                text:'Data dikirim ke Risk Officer untuk penjadwalan mitigasi',
                confirmButtonText: 'Kembali ke List',
                reverseButtons:true,
                allowEscapeKey:false,
                allowEnterKey:false,
                allowOutsideClick:false
              }).then((result) => {
                if(result.value){
                  this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
                }
              })
            }else{
              Swal.fire({
                title: "Nilai Residual Berhasil Diisi!",
                icon: "success",
                confirmButtonText: 'Kembali ke List',
                reverseButtons:true,
                allowEscapeKey:false,
                allowEnterKey:false,
                allowOutsideClick:false
              }).then((result) => {
                if(result.value){
                  this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
                }
              })
            }
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    }
  }
}
</script>