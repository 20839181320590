<template>
<div>
  <b-card v-if="_row.trp_id" title="Detail Risiko">
    <div class="wrap_info_preview">
      <b-row class="mb-3">
        <b-col md=5>
          <div class="wrap_preview_prof">
            <h3>ID Risiko</h3>
            <p>{{ _row.trp_register_code }}</p>
          </div>
        </b-col>
        <b-col md=5>
          <div class="wrap_preview_prof">
            <h3>Periode</h3>
            <p v-if="_row.trp_is_periode=='triwulan'"> 
              Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
            </p>
            <p v-else>
              {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md=5>
          <div class="wrap_preview_prof">
            <h3>Kantor</h3>
            <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
          </div>
        </b-col>
        <b-col md=5>
          <div class="wrap_preview_prof">
            <h3>Unit Kerja</h3>
            <p> {{_row.muk_description}}</p>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-alert 
      v-if="_row.trp_approve_status=='N' && _row.trp_approve_reason"
      variant="danger" 
      class="mt-4 text-white"
      style="background:#d32929"
      show
    >
      <p>
        <b>Alasan Penolakan:</b>
        <span class="d-block">{{ _row.trp_approve_reason }}</span>
      </p>
    </b-alert>

    <div class="mt-4">

      <VTab>
        <template slot="link">
          <VTabLink :step.sync="tab" option="1">REGISTER RISIKO</VTabLink>
          <VTabLink :step.sync="tab" option="2">HASIL PENAKSIRAN</VTabLink>
          <VTabLink v-if="_row.trp_current_status=='Y'" :step.sync="tab" option="3">CURRENT</VTabLink>
          <VTabLink :step.sync="tab" option="5">TINDAKAN MITIGASI</VTabLink>
          <VTabLink :step.sync="tab" option="4">MITIGASI</VTabLink>
          <VTabLink :step.sync="tab" option="7">LOGS</VTabLink>
          <VTabLink :step.sync="tab" option="6">ROLLBACK LOGS</VTabLink>
        </template>
        <template slot="content">
          <VTabContent :step.sync="tab" option=1>
            <div 
              class="wrap_info_risiko_detail mt-2"
              v-for="(v,k) in risiko"
              :key="k"
            >
              <div class="table-responsive">
                <table class="table no-border">
                  <tbody>
                    <tr>
                      <td class="align-top font-weight-bold" rowspan="8" style="width: 30px;">
                        <div class="list_numb_circle">{{k+1}}</div>
                      </td>
                      <td class="font-weight-bold" style="width:20%">Sasaran RTU</td>
                      <td>{{v.sasaran_rtu}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nama Risiko</td>
                      <td>{{v.nama}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold align-top">Risiko Sebab</td>
                      <td>
                        <ol 
                          class="list mb-0" 
                          style="padding-left: 15px;"
                        >
                          <li
                            v-for="(v2,k2) in v.sebab"
                            :key="k+'.sebab.'+k2"
                            class="m-0"
                          >
                            <span>{{v2.nama}}</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold align-top">Risiko Dampak</td>
                      <td>
                        <ol 
                          class="list mb-0" 
                          style="padding-left: 15px;"
                        >
                          <li
                            v-for="(v2,k2) in v.dampak"
                            :key="k+'.dampak.'+k2"
                            class="m-0"
                          >
                            <span>{{v2.nama}}</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold align-top">Risiko Pengendalian</td>
                      <td>
                        <ol 
                          class="list mb-0" 
                          style="padding-left: 15px;"
                        >
                          <li
                            v-for="(v2,k2) in v.kendali"
                            :key="k+'.kendali.'+k2"
                            class="m-0"
                          >
                            <span>{{v2.nama}}</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </VTabContent>
          <VTabContent :step.sync="tab" option=7>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Aktifitas</th>
                    <th>Dilakukan Pada</th>
                    <th>Dilakukan Oleh</th>
                  </tr>
                </thead>
                <tbody v-if="!logs.length">
                  <tr>
                    <th colspan="4">
                      <center>Tidak Ada Logs.</center>
                    </th>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(v,k) in logs" :key="k">
                    <td>{{ k+1 }}</td>
                    <td>
                      <p>{{ v.activity }}</p>
                      <template v-if="v.gantiKantor">
                        <table class="table table-bordered">
                          <tr>
                            <td>Atas Nama:</td>
                            <td>{{ v.gantiKantor.name }}</td>
                          </tr>
                          <tr>
                            <td>Sebagai:</td>
                            <td>{{ v.gantiKantor.level }}</td>
                          </tr>
                          <tr v-if="v.gantiKantor.officeName">
                            <td>Kantor:</td>
                            <td>{{ v.gantiKantor.officeName }} ({{ v.gantiKantor.officeCode }})</td>
                          </tr>
                          <tr v-if="v.gantiKantor.unitKerjaName">
                            <td>Unit Kerja:</td>
                            <td>{{ v.gantiKantor.unitKerjaName }}</td>
                          </tr>
                        </table>
                      </template>
                    </td>
                    <td>{{ v.created_date | moment('DD MMMM YYYY HH:mm') }}</td>
                    <td>{{ v.created_by.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </VTabContent>
          <VTabContent :step.sync="tab" option=6>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Ke-Step</th>
                    <th>Dilakukan Pada</th>
                  </tr>
                </thead>
                <tbody v-if="!rollback_logs.length">
                  <tr>
                    <th colspan="3">
                      <center>Tidak Ada Logs.</center>
                    </th>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(v,k) in rollback_logs" :key="k">
                    <td>{{ k+1 }}</td>
                    <td>{{ v.trbl_back_step.replace(/\_/g,' ') }}</td>
                    <td>{{ v.trbl_created_date | moment('DD MMMM YYYY HH:mm') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </VTabContent>
          <VTabContent :step.sync="tab" option=2>
            <b-table
              :fields="fields"
              :items="risiko"
              small
              show-empty
              class="m-b-0 mt-2 table-hover table-bordered"
              bordered
              responsive
            >
              <template #empty>
                <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
              </template>
              <template #thead-top>
                <b-tr>
                  <b-th 
                    :colspan="3"
                    class="full-color-table full-purple-table"
                  />
                  <b-th 
                    colspan="3"
                    class="text-center full-color-table full-danger-table"
                  >
                    <center>Inheren</center>
                  </b-th>
                  <b-th 
                    colspan="3"
                    class="text-center full-color-table full-info-table"
                  >
                    <center>Current</center>
                  </b-th>
                  <b-th 
                    colspan="3"
                    class="text-center full-color-table full-warning-table"
                  >
                    <center>Residual</center>
                  </b-th>
                </b-tr>
              </template>
              <template #cell(number)="data">
                {{data.index+1}}
              </template>
              <template #cell(doc_evidence)="data">
                <template v-if="data.value">
                  <ImagePreview 
                    :src="uploader(data.value)"
                    type="blank"
                  >
                    <a :href="uploader(data.value)" target="_blank" rel="noopener noreferrer" class="txt_link_download"> 
                      <i class="far fa-file-pdf"></i> Dokumen
                    </a>
                  </ImagePreview>
                </template>
                <span v-else>-</span>
              </template>
              <template #cell(inheren_k)="data">
                {{data.value||'-'}}
              </template>
              <template #cell(inheren_d)="data">
                {{data.value||'-'}}
              </template>
              <template #cell(inheren_kd)="data">
                <template v-if="_row.trp_inherent_status=='Y'">
                  {{ round(data.item.inheren_k * data.item.inheren_d) }}
                </template>
                <span v-else>-</span>
              </template>
              <template #cell(current_k)="data">
                <template v-if="_row.trp_current_status=='Y'">
                  {{round(data.value)||'-'}}
                </template>
                <template v-else>-</template>
              </template>
              <template #cell(current_d)="data">
                <template v-if="_row.trp_current_status=='Y'">
                  {{round(data.value)||'-'}}
                </template>
                <template v-else>-</template>
              </template>
              <template #cell(current_kd)="data">
                <template v-if="_row.trp_current_status=='Y'">
                  {{ round(data.item.current_k * data.item.current_d) }}
                </template>
                <span v-else>-</span>
              </template>
              <template #cell(risidual_k)="data">
                {{data.value||'-'}}
              </template>
              <template #cell(risidual_d)="data">
                {{data.value||'-'}}
              </template>
              <template #cell(risidual_kd)="data">
                <template v-if="_row.trp_residual_status=='Y'">
                  {{ round(data.item.risidual_k * data.item.risidual_d) }}
                </template>
                <span v-else>-</span>
              </template>
            </b-table>
          </VTabContent>
          <VTabContent :step.sync="tab" option=3>
            <div class="table-responsive mt-2">
              <table class="table m-b-0  table-bordered">
                <thead>
                  <tr class="full-color-table full-purple-table">
                    <th> # </th>
                    <th> Kendali </th>
                    <th> Terkait </th>
                    <th width="50%"> Penilaian </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(val,key) in current">
                    <tr 
                      :key="'a'+key"
                      class="full-color-table full-info-table"
                    >
                      <th>
                        <div class="list_numb_circle">{{key+1}}</div>
                      </th>
                      <th colspan="3"> 
                        <b-row>
                          <b-col>
                            {{val.risiko_name}}
                          </b-col>
                          <b-col 
                            v-if="_row.trp_is_periode=='triwulan'"
                            md=3
                          >
                            <ImagePreview 
                              :src="uploader(val.doc_evidence)"
                              type="blank"
                            >
                              <a :href="uploader(val.doc_evidence)" target="_blank" rel="noopener noreferrer" class="txt_link_download"> 
                                <i class="far fa-file-pdf"></i> Doc. Pengendalian
                              </a>
                            </ImagePreview>
                          </b-col>
                        </b-row>
                      </th>
                    </tr>

                    <template v-for="(kendaliVal,kendaliKey) in val.kendali">
                      <tr 
                        :key="'b'+key+''+kendaliKey"
                        class="unread"
                      >
                        <td> </td>
                        <td style="min-width:150px">{{ kendaliVal.nama }}</td>
                        <td style="min-width:150px">{{ kendaliVal.terkait=='sebab'?'kemungkinan':(kendaliVal.terkait||'-') }}</td>
                        <td style="min-width:1000px">
                          <template v-for="(pointVal,pointKey) in QuestionCurrent">
                            <b-row :key="kendaliKey+'.'+pointKey">
                              <b-col md=4>
                                {{pointVal.label}}
                              </b-col>
                              <b-col md=6>
                                <div class="d-flex align-items-right">
                                  <template v-for="(answerVal, answerKey) in kendaliVal.answer">
                                    <VSelect
                                      :key="answerKey"
                                      v-model="answerVal[pointVal.value]" 
                                      placeholder="--Pilih Nilai--"
                                      :options="pointVal.option"
                                      :clearable="false" 
                                      :reduce="v=>v.value" 
                                      disabled
                                      class="mr-1"
                                    />
                                  </template>
                                </div>
                              </b-col>
                            </b-row>
                            <hr 
                              v-if="pointKey != QuestionCurrent.length-1"
                              :key="kendaliKey+'.b.'+pointKey"
                            />
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </VTabContent>
          <VTabContent :step.sync="tab" option=4>
            <div class="inbox-center table-responsive mt-2">
              <table class="table m-b-0 table-striped table-hover">
                <thead>
                  <tr class=" text-center  full-color-table full-purple-table">
                    <th> # </th>
                    <th> Nama Risiko </th>
                    <th> Perkiraan Biaya (Rp) </th>
                    <th> Perkiraan Jadwal </th>
                    <th> PIC </th>
                    <th style="min-width:200px"> Doc. Mitigasi </th>
                    <th style="min-width:200px"> Hasil </th>
                  </tr>
                </thead>
                <tbody v-if="mitigasi.length">
                  <tr 
                    v-for="(v,k) in mitigasi"
                    :key="k"
                    class="unread"
                  >
                    <td>{{k+1}}</td>
                    <td>{{ v.nama }}</td>
                    <td>Rp {{ v.biaya.format() }}</td>
                    <td>{{ v.jadwal_awal | moment("DD MMMM YYYY") }} - {{ v.jadwal_akhir | moment("DD MMMM YYYY") }}</td>
                    <td>{{v.pic}}</td>
                    <td style="text-align:center">
                      <template v-if="v.doc">
                        <ImagePreview 
                          :src="uploader(v.doc)"
                          type="blank"
                        >
                          <a :href="uploader(v.doc)" target="_blank" rel="noopener noreferrer" class="txt_link_download"> 
                            <i class="far fa-file-pdf"></i> Dokumen
                          </a>
                        </ImagePreview>
                      </template>
                      <span v-else>-</span>
                    </td>
                    <td>
                      {{v.hasil||'-'}}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <th colspan="7">
                      <center>Belum ada mitigasi.</center>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </VTabContent>
          <VTabContent :step.sync="tab" option=5>
            <div class="inbox-center table-responsive mt-2">
              <table class="table m-b-0 table-striped">
                <thead>
                  <tr class=" text-center  full-color-table full-purple-table">
                    <th> # </th>
                    <th> Nama Risiko </th>
                    <th> Tindakan Mitigasi </th>
                  </tr>
                </thead>
                <tbody v-if="tindakanMitigasi.length">
                  <tr 
                    v-for="(v,k) in tindakanMitigasi"
                    :key="k"
                  >
                    <td>{{k+1}}</td>
                    <td>{{ v.mr_name }}</td>
                    <td>
                      <ol>
                        <li v-for="(value, index) in v.tindakan" :key="index">
                          {{value.nama}} 
                          <span class="badge badge-info">
                            {{ value.terkait=='sebab'?'kemungkinan':(value.terkait||'-') }}
                          </span>
                        </li>
                      </ol>
                      {{v.tindakan.length == 0 ? '-' : ''}}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <th colspan="7">
                      <center>Belum ada tindakan mitigasi.</center>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </VTabContent>
        </template>
      </VTab>

    </div>

    <template #footer>
      <b-row>
        <b-col md=6>
          <b-button
            class="btn waves-effect btn-rounded waves-light btn-outline-dark"
            :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
          >
            Kembali
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-card>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
export default {
  extends:GlobalVue,
  components:{VTab, VTabLink, VTabContent},
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      tab:1
    }
  },
  computed:{
    fields(){
      let fields = [
        {key:'number', label:'#', thClass:'full-color-table full-purple-table v_middle'},
        {key:'risiko_name', label:'Nama Risiko', thClass:'full-color-table full-purple-table v_middle'}
      ];
      // if(this._row.trp_is_periode=='triwulan'){
      fields = _.union(fields, [
        {key:'doc_evidence', label:'Doc. Pengendalian', thClass:'full-color-table full-purple-table v_middle'}
      ])
      // }
      fields = _.union(fields, [
        {key:'inheren_k', label:'K', thClass:'text-center full-color-table full-danger-table'},
        {key:'inheren_d', label:'D', thClass:'text-center full-color-table full-danger-table'},
        {key:'inheren_kd', label:'K x D', thClass:'text-center full-color-table full-danger-table' },
        {key:'current_k', label:'K', thClass:'text-center full-color-table full-info-table'},
        {key:'current_d', label:'D', thClass:'text-center full-color-table full-info-table'},
        {key:'current_kd', label:'K x D', thClass:'text-center full-color-table full-info-table' },
        {key:'risidual_k', label: 'K', thClass:'text-center full-color-table  full-warning-table'},
        {key:'risidual_d', label: 'D', thClass:'text-center full-color-table  full-warning-table'},
        {key:'risidual_kd', label:'K x D', thClass:'text-center full-color-table full-warning-table ' }
      ])

      return fields
    },
    risiko(){
      return this._post?.risiko
    },
    QuestionCurrent(){
      return this._post?.QuestionCurrent||[]
    },
    current(){
      return this._post?.current
    },
    mitigasi(){
      return this._post?.mitigasi
    },
    logs(){
      return this._post?.logs
    },
    rollback_logs(){
      return this._post?.rollback_logs
    },
    tindakanMitigasi(){
      return this._post?.tindakanMitigasi
    }
  },
  methods:{
  }
}
</script>

<style>
.customtab li a.nav-link, .profile-tab li a.nav-link{
  padding:15px !important
}
</style>