<template>
<div>
  <b-card v-if="_row.trp_id" title="Isi Nilai Current">
    <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
      <form @submit.prevent="handleSubmit(doSubmitForm)">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ _row.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="_row.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }} - {{ _row.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p>{{_row.muk_code.toUpperCase()}} - {{_row.muk_level.toUpperCase()}} - {{_row.muk_description.toUpperCase()}}</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="card b-all shadow-none mt-3 mb-1">
          <div :class="{'table-responsive':isMobile}">
            <table class="table m-b-0  table-bordered">
              <thead>
                <tr class="full-color-table full-purple-table">
                  <th> # </th>
                  <th> Kendali </th>
                  <th> Terkait </th>
                  <th width="55%"> Penilaian </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(val,key) in current">
                  <tr 
                    :key="'a'+key"
                    class="full-color-table full-info-table"
                  >
                    <th>
                      <div class="list_numb_circle">{{key+1}}</div>
                    </th>
                    <th colspan="3"> 
                      <b-row>
                        <b-col>
                          {{val.risiko_name}}
                        </b-col>
                        <b-col 
                          v-if="_row.trp_is_periode=='triwulan'"
                          md=3
                        >
                          <ImagePreview 
                            :src="uploader(val.doc_evidence)"
                          >
                            <a href="javascript:;" class="txt_link_download"> 
                              <i class="far fa-file-pdf"></i> Doc. Pengendalian
                            </a>
                          </ImagePreview>
                        </b-col>
                        <b-col md=3 class="text-right">
                          <a 
                            href="javascript:;" 
                            style="vertical-align:center" 
                            class="btn btn-info"
                            @click="doOpenModal(val)"
                          >
                            <i class="icon-book-open"></i> Pustaka Risiko
                          </a> 
                        </b-col>
                      </b-row>
                    </th>
                  </tr>

                  <template v-for="(kendaliVal,kendaliKey) in val.kendali">
                    <tr 
                      :key="'b'+key+''+kendaliKey"
                      class="unread"
                    >
                      <td> </td>
                      <td>{{ kendaliVal.nama }}</td>
                      <td>{{ kendaliVal.terkait=='sebab'?'kemungkinan':(kendaliVal.terkait||'-') }}</td>
                      <td>
                        <template v-for="(pointVal,pointKey) in QuestionCurrent">
                          <b-row :key="kendaliKey+'.'+pointKey">
                            <b-col>
                              {{pointVal.label}}
                            </b-col>
                            <b-col md=4>
                              <VSelect
                                v-model="kendaliVal[pointVal.value]" 
                                placeholder="--Pilih Nilai--"
                                :options="pointVal.option"
                                :clearable="false" 
                                :reduce="v=>v.value"
                                :disabled="kendaliVal.disabled ? kendaliVal.disabled[pointVal.value] : false"
                                @input="checkTidakQ1(kendaliKey, key)"
                              />
                              <VValidate 
                                :name="'Nilai ke-'+(key+1)+'.'+(kendaliKey+1)+'.'+(pointKey+1)"
                                v-model="kendaliVal[pointVal.value]" 
                                rules="required" 
                              />
                            </b-col>
                            <b-col v-if="kendaliVal['prev_'+pointVal.value]" md=3>
                              <VSelect
                                v-model="kendaliVal['prev_'+pointVal.value]" 
                                placeholder="Sebagian"
                                :options="pointVal.option"
                                :clearable="false" 
                                :reduce="v=>v.value"
                                disabled
                              />
                            </b-col>
                          </b-row>
                          <hr 
                            v-if="pointKey != QuestionCurrent.length-1"
                            :key="kendaliKey+'.b.'+pointKey"
                          />
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>

      </form>
    </ValidationObserver>

    <template #footer>
      <b-row class="mt-2">
        <b-col md=6>
          <b-button
            class="btn waves-effect btn-rounded waves-light btn-outline-dark"
            :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
          >
            Kembali
          </b-button>
        </b-col>
        <b-col md=6>
          <div align="right">
            <b-button
              class="btn btn-rounded  btn-info"
              @click="doSubmitForm()"
            >
              Konfirmasi Penaksiran
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
  <b-modal 
    id="RisikoModal" 
    :title="'Data Pustaka - '+modal.risiko_name"
    size="xl"
    hide-footer
  >
    <VTab :justify="true">
      <template slot="link">
        <VTabLink :step.sync="tabRisiko" option="sebab">Sebab</VTabLink>
        <VTabLink :step.sync="tabRisiko" option="dampak">Dampak</VTabLink>
        <VTabLink :step.sync="tabRisiko" option="kendali">Pengendalian</VTabLink>
      </template>
      <template slot="content">
        <VTabContent :step.sync="tabRisiko" :option="tabRisiko">
          <div class="inbox-center table-responsive">
            <table class="table m-b-0 table-bordered">
              <thead>
                <tr class="full-color-table full-purple-table" >
                  <th> # </th>
                  <th>Kategori</th>
                  <th>Deskripsi</th>
                  <th v-if="tabRisiko=='kendali'">Terkait</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(v,k) in modal[tabRisiko]"
                  :key="k"
                >
                  <td class="text-center">{{ k + 1 }}</td>
                  <td>{{ v.kategori }}</td>
                  <td>{{ v.nama }}</td>
                  <td v-if="tabRisiko=='kendali'">
                    {{ v.terkait=='sebab'?'kemungkinan':(v.terkait||'-') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </VTabContent>
      </template>
    </VTab>
  </b-modal>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let Swal = global.Swal
export default {
  extends:GlobalVue,
  components:{
    VTab, VTabLink, VTabContent
  },
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      modal:{},
      tabRisiko:''
    }
  },
  computed:{
    current(){
      return this._post?.current||[]
    },
    QuestionCurrent(){
      return this._post?.QuestionCurrent||[]
    }
  },
  methods:{
    doOpenModal(data){
      this.$set(this,'modal', data)
      this.$set(this,'tabRisiko', "sebab")
      this.$bvModal.show('RisikoModal')
    },
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'DoCurrent',
          id: this.pageId,
          current:_.clone(this.current)
        }, 
        (type, resp)=>{
          if(type=='success'){
            if(resp.need_mitigasi=='Y' && resp.current_status=='Y'){
              Swal.fire({
                title: "Nilai Current Berhasil Diisi!",
                icon: "success",
                text:'Data dikirim ke Risk Officer untuk diisi tindakan mitigasi',
                confirmButtonText: 'Kembali ke List',
                reverseButtons:true,
                allowEscapeKey:false,
                allowEnterKey:false,
                allowOutsideClick:false
              }).then((result) => {
                if(result.value){
                  this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
                }
              })
            }else{
              Swal.fire({
                title: "Nilai Current Berhasil Diisi!",
                icon: "success",
                confirmButtonText: 'Kembali ke List',
                reverseButtons:true,
                allowEscapeKey:false,
                allowEnterKey:false,
                allowOutsideClick:false
              }).then((result) => {
                if(result.value){
                  this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
                }
              })
            }
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    },
    checkTidakQ1(kendaliKey, key){
      // Check Jika pertanyaan pertama jawabannya Tidak
      if(this.current[key].kendali[kendaliKey].q1 == 6){
        this.current[key].kendali[kendaliKey].q2 = 3
        this.current[key].kendali[kendaliKey].q3 = 3
        this.current[key].kendali[kendaliKey].disabled = {
          q2: true,
          q3: true
        }
      }else {
        this.current[key].kendali[kendaliKey].disabled = {
          q2: false,
          q3: false
        }
      }
    }
  }
}
</script>
<style scoped>
.table td, .table th {
  padding: .5rem 1rem;
}
</style>