<template>
<div>
  <div class="container-fluid">
    <PageTitle :noAdd="true" :singlePage="true">
      <router-link 
        v-if="!isList && row"
        :to="{name:$route.name, query:{tab:row.trp_is_periode}}" 
        class="btn btn-info d-none d-lg-block m-l-15"
      >
        <i class="fas fa-list-ul m-r-15"></i> List {{pageTitle}}
      </router-link>
    </PageTitle>

    <template v-if="isList">
      <b-overlay :show="!dataList">
        <VTab>
          <template slot="link">
            <VTabLink :step.sync="tab" option="bulan">BULAN</VTabLink>
            <VTabLink :step.sync="tab" option="triwulan">TRIWULAN</VTabLink>
          </template>
          <template slot="content">
            <VTabContent :step.sync="tab" :option="tab">
              <card-list 
                :filter.sync="filter"
                :dataListing.sync="data"
                :idKey="idKey"
                :fields="fields"
                
                :no-multi-select="true"
                :no-btn-mass="true"
                :header-full="true"
              > 
                <template #filters>
                  <b-col md="3" class="mb-2">
                    <VSelect 
                      v-model="filter.tahun" 
                      placeholder="-- Semua Tahun --"
                      :options="mrTahun" 
                      :reduce="v=>String(v.value)" 
                      @input="doFilter"
                    />
                  </b-col>
                  <b-col md="3">
                    <VSelect 
                      v-model="filter.bulan" 
                      placeholder="-- Semua Bulan --"
                      :options="Config.master.OptionBulan" 
                      :reduce="v=>String(v.value)" 
                      @input="doFilter"
                    />
                  </b-col>
                  <b-col md=6> </b-col>
                  
                  <template v-if="moduleRole('isAdministratorSystem') || moduleRole('isAuditor')">
                    <b-col md=3 class="mb-2">
                      <VSelect 
                        v-model="filter.level" 
                        placeholder="-- Semua Level --"
                        :options="mrLevel" 
                        :reduce="v=>String(v.value)" 
                        @input="doFilter"
                      />
                    </b-col>
                    <b-col md=3 class="mb-2">
                      <VSelect 
                        v-model="filter.kantor" 
                        placeholder="-- Semua Kantor --"
                        :options="mrKantor" 
                        :reduce="v=>String(v.value)" 
                        @input="doFilter"
                      />
                    </b-col>
                    <b-col md=3 class="mb-2">
                      <VSelect 
                        v-model="filter.unit_kerja" 
                        placeholder="-- Semua Unit --"
                        :options="mrUnitKerja" 
                        :reduce="v=>String(v.value)" 
                        @input="doFilter"
                      />
                    </b-col>
                    <b-col md=3> </b-col>
                  </template>

                  <b-col md="3">
                    <VSelect 
                      v-model="filter.status_approve" 
                      placeholder="-- Semua Status --"
                      :options="mrStatusApprove" 
                      :reduce="v=>String(v.value)" 
                      @input="doFilter"
                    />
                  </b-col>
                  <b-col md="3">
                    <VSelect 
                      v-model="filter.status_penilaian" 
                      placeholder="-- Semua Penilaian --"
                      :options="mrStatusPenaksiran" 
                      :reduce="v=>String(v.value)" 
                      @input="doFilter"
                    />
                  </b-col>
                </template>
                <template #trp_tgl_register="data">
                  <span class="text-semibold text-info">{{ data.scope.item.trp_register_code }}</span>
                  <small class="d-block">
                    {{ data.scope.value | moment('DD MMMM YYYY') }}
                  </small>
                </template>
                <template #trp_bulan="data">
                  {{'2021-'+data.scope.value+'-01' | moment('MMMM')}} {{data.scope.item.trp_tahun}}
                </template>
                <template #mo_name="data">
                  {{data.scope.item.mo_code}} - {{ data.scope.item.mo_level.toUpperCase() }} - {{ data.scope.value }}
                </template>
                <template #muk_description="data">
                  {{data.scope.item.muk_code.toUpperCase()}} - {{ data.scope.item.muk_level.toUpperCase() }} - {{ data.scope.value.toUpperCase() }}
                </template>
                <template #status="data">
                  <span
                    v-if="data.scope.item.trp_is_draft=='Y'"
                    class="label label-primary"
                  >
                    Draft
                  </span>
                  <span 
                    v-else-if="data.scope.item.trp_hold_to_pjrtu=='Y'"
                    class="label label-warning"
                  >
                    Belum Terpenuhi
                  </span>
                  <span 
                    v-else-if="data.scope.item.trp_approve_status=='D'"
                    class="label label-info text-center"
                    style="width:88px !important"
                  >
                    Menunggu Persetujuan
                  </span>
                  <span 
                    v-else-if="data.scope.item.trp_approve_status=='N'"
                    class="label label-danger"
                  >
                    Ditolak
                  </span>
                  <span 
                    v-else-if="data.scope.item.trp_approve_status=='R'"
                    class="label label-warning"
                  >
                    Revisi
                  </span>
                  <span 
                    v-else-if="data.scope.item.trp_approve_status=='Y'"
                    class="label label-success"
                  >
                    Disetujui
                  </span>
                </template>
                <template #penaksiran="data">
                  <PenaksiranStatus :_row="data.scope.item" :periodeCurrent="periodeCurrent"/>
                </template>
                <template #aksi="data">
                  <b-button-group>
                    <template 
                      v-if="
                      canEdit(data.scope.item) &&
                      data.scope.item.trp_approve_status=='D' && 
                      data.scope.item.trp_hold_to_pjrtu=='N' && 
                      data.scope.item.trp_is_draft=='N' &&
                      moduleRole('isPJRTU')"
                    >
                      <b-button
                        variant="success"
                        @click="doOpenModal('Detail', data.scope.item)"
                      >
                        <div v-b-tooltip.hover="'Setujui'">
                          <i class="fas fa-check"></i>
                        </div>
                      </b-button>
                      <!-- <b-button
                        variant="danger"
                        @click="doOpenModal('Detail', data.scope.item)"
                      >
                        <div v-b-tooltip.hover="'Tolak'">
                          <i class="ti-close"></i>
                        </div>
                      </b-button> -->
                      <b-button
                        variant="warning"
                        @click="doOpenModal('Detail', data.scope.item)"
                      >
                        <div v-b-tooltip.hover="'Perlu Direvisi'">
                          <i class="ti-pencil"></i>
                        </div>
                      </b-button>
                    </template>
                    <template v-if="
                      canEdit(data.scope.item,['N','Y'])
                    ">
                      <template 
                        v-if=" 
                          (data.scope.item.trp_is_draft=='Y' || data.scope.item.trp_approve_status=='R' || data.scope.item.trp_hold_to_pjrtu=='Y')
                          && (moduleRole('isRiskOfficer') || moduleRole('isAdministratorSystem') || moduleRole('isAuditor'))
                          && data.scope.item.trp_locked_periode=='N'
                        "
                      >
                        <b-button
                          variant="info"
                          :to="{name:'RegisterResiko', params:{pageSlug:data.scope.item[idKey]}}"
                        >
                          <div 
                            v-b-tooltip.hover
                            :title="(data.scope.item.trp_approve_status=='R' ? 'Revisi Register Risiko' : 'Lanjutkan Pengisian' )"
                          >
                            <i class="ti-pencil"></i>
                          </div>
                        </b-button>
                      </template>

                      <template 
                        v-if="moduleRole('UnLockRisiko') && data.scope.item.trp_request_unlock==='Y'"
                      >
                        <b-button
                          variant="info"
                          @click="doOpenModal('UnLockStepFUModal', data.scope.item)"
                        >
                          <div 
                            v-b-tooltip.hover
                            :title="( data.scope.item.trp_locked_periode=='Y' ? 'Unlock Periode' :'Unlock Rollback Step' )"
                          >
                            <i class="ti-unlock"></i>
                          </div>
                        </b-button>
                      </template>

                      <template 
                        v-if="
                        moduleRole('RollbackStep') && 
                        data.scope.item.trp_request_unlock=='N' && (
                          data.scope.item.trp_inherent_status=='Y' ||
                          data.scope.item.trp_locked_periode=='Y'
                        )"
                      >
                        <b-button
                          v-if="data.scope.item.trp_selesai_date || data.scope.item.trp_locked_periode=='Y'"
                          variant="info"
                          @click="doOpenModal('UnlockStepRequest', data.scope.item)"
                        >
                          <div 
                            v-b-tooltip.hover
                            :title="( data.scope.item.trp_locked_periode=='Y' ? 'Request Unlock Periode' :'Request Unlock Step' )"
                          >
                            <i class="ti-unlock"></i>
                          </div>
                        </b-button>
                        <b-button
                          v-else
                          variant="info"
                          @click="doOpenModal('BackStepModal', data.scope.item)"
                        >
                          <div 
                            v-b-tooltip.hover
                            title="Rollback Step"
                          >
                            <i class="ti-reload"></i>
                          </div>
                        </b-button>
                      </template>
                      
                    </template>
                    <b-button
                      variant="secondary"
                      :to="{name:$route.name, params:{pageSlug:'detail', pageId:data.scope.item[idKey]}}"
                    >
                      <div 
                        v-b-tooltip.hover
                        title="Lihat Detail"
                      >
                        <i class=" icon-eye"></i>
                      </div>
                    </b-button>
                  </b-button-group>
                </template>
              </card-list>
            </VTabContent>
          </template>
        </VTab>
      </b-overlay>
    </template>
    <template v-else-if="pageSlug">
      <template 
        v-if="pageSlug=='inheren'"
      >
        <PenaksiranInherent 
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='upload-evidence'"
      >
        <PenaksiranEvidence 
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='current'"
      >
        <PenaksiranCurrent 
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='tindakan-mitigasi'"
      >
        <PenaksiranTindakanMitigasi 
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='residual'"
      >
        <PenaksiranResidual 
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='jadwal-mitigasi'"
      >
        <PenaksiranJadwalMitigasi 
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='hasil-mitigasi'"
      >
        <PenaksiranHasilMitigasi
          :_row="row"
          :_post="post"
        />
      </template>
      <template 
        v-else-if="pageSlug=='detail'"
      >
        <PenaksiranDetail
          :_row="row"
          :_post="post"
        />
      </template>
    </template>

    <b-modal 
      id="Detail" 
      title="Follow Up Risiko"
      size="xl"
      dialog-class="modal_xl_custom"
      :hide-footer="true"
      :busy="loadingOverlay"
    >
      <template v-if="modal.risiko">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ modal.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="modal.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ modal.trp_triwulan }} {{ modal.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+modal.trp_bulan+'-01' | moment('MMMM') }}-{{ modal.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{modal.mo_code}} - {{ (modal.mo_level||"").toUpperCase() }} - {{modal.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p> {{modal.muk_code}} - {{ (modal.muk_level||"").toUpperCase() }} - {{modal.muk_description}}</p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="card">
          <div class="card-header">
            Data Register Risiko
          </div>
          <div class="card-body">
            <div 
              class="wrap_info_risiko_detail"
              v-for="(v,k) in modal.risiko"
              :key="k"
            >
              <div class="table-responsive">
                <table class="table no-border">
                  <tbody>
                    <tr>
                      <td class="align-top font-weight-bold" rowspan="8" style="width: 30px;">
                        <div class="list_numb_circle">{{k+1}}</div>
                      </td>
                      <td class="font-weight-bold" style="width:20%">Sasaran RTU</td>
                      <td>{{v.sasaran_rtu}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nama Risiko</td>
                      <td>{{v.nama}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold align-top">Risiko Sebab</td>
                      <td>
                        <ol 
                          class="list mb-0" 
                          style="padding-left: 15px;"
                        >
                          <li
                            v-for="(v2,k2) in v.sebab"
                            :key="k+'.sebab.'+k2"
                            class="m-0"
                          >
                            <span>{{v2.nama}}</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold align-top">Risiko Dampak</td>
                      <td>
                        <ol 
                          class="list mb-0" 
                          style="padding-left: 15px;"
                        >
                          <li
                            v-for="(v2,k2) in v.dampak"
                            :key="k+'.dampak.'+k2"
                            class="m-0"
                          >
                            <span>{{v2.nama}}</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold align-top">Risiko Pengendalian</td>
                      <td>
                        <ol 
                          class="list mb-0" 
                          style="padding-left: 15px;"
                        >
                          <li
                            v-for="(v2,k2) in v.kendali"
                            :key="k+'.kendali.'+k2"
                            class="m-0"
                          >
                            <span>{{v2.nama}}</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div 
            class="card-footer bg_gray"
            style="position: fixed; width: 100%; text-align: center; background: #fff; bottom: 0; right: 0; margin: auto; left: 0;"
          >
            <div class="form-action text-center">
              <!-- <button 
                type="button" 
                class="btn btn-rounded btn-danger mr-1"
                @click="doOpenModal('RejectModal', modal)"
              >
                Tolak
              </button> -->
              <button
                type="button" 
                class="btn btn-rounded btn-warning mr-1"
                @click="doOpenModal('ReviseModal', modal)"
              >
                Revisi
              </button>
              <button 
                type="button" 
                class="btn btn-rounded btn-info"
                @click="doOpenModal('ApproveModal', modal)"
              >
                Proses ke Tahap Penilaian
              </button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal 
      id="UnLockStepFUModal" 
      size="md"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
    >
      <b-overlay :show="loadingOverlay">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ modal.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="modal.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ modal.trp_triwulan }} {{ modal.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+modal.trp_bulan+'-01' | moment('MMMM') }}-{{ modal.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{modal.mo_code}} - {{ (modal.mo_level||"").toUpperCase() }} - {{modal.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p> {{modal.muk_code}} - {{ (modal.muk_level||"").toUpperCase() }} - {{modal.muk_description}}</p>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-card v-if="Object.keys(modal).length" class="p-3">
          <h4 class="font-weight-bold text-center">
            <template v-if="modal.trp_locked_periode=='Y'">
              Menginjinkan Risk Officer melanjutkan pengisian register risiko
            </template>
            <template v-else-if="modal.trp_risiko_current_plus5>0">
              Mengijinkan Risk Officer untuk membuka step Jadwal Mitigasi?
            </template>
            <template v-else>
              Mengijinkan Risk Officer untuk membuka step Current?
            </template>
          </h4>

          <template #footer>
            <div align="right">
              <b-button 
                variant="danger"
                class="mr-2"
                @click="doSubmitModal('UnlockStepRejected')"
              >
                Tolak
              </b-button>
              <b-button 
                variant="success"
                @click="doSubmitModal('UnlockStepApproved')"
              >
                Setujui
              </b-button>
            </div>
          </template>
        </b-card>
      </b-overlay>
    </b-modal>

    <b-modal 
      id="UnlockStepRequest" 
      size="md"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
    >
      <b-overlay :show="loadingOverlay">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ modal.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="modal.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ modal.trp_triwulan }} {{ modal.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+modal.trp_bulan+'-01' | moment('MMMM') }}-{{ modal.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{modal.mo_code}} - {{ (modal.mo_level||"").toUpperCase() }} - {{modal.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p> {{modal.muk_code}} - {{ (modal.muk_level||"").toUpperCase() }} - {{modal.muk_description}}</p>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-card v-if="Object.keys(modal).length" class="p-3">
          <h4 v-if="modal.trp_locked_periode=='Y'" class="font-weight-bold text-center">
            Untuk membuka penguncian register risiko ini, Anda harus mengirim permintaan ke Admin Pusat.
          </h4>
          <h4 v-else class="font-weight-bold text-center">
            Untuk mengulang pengisian nilai periode risiko ini, Anda harus mengirim permintaan ke Admin Pusat.
          </h4>

          <template #footer>
            <div align="right">
              <b-button 
                variant="secondary"
                class="mr-2"
                @click="$bvModal.hide('UnlockStepRequest')"
              >
                Batal
              </b-button>
              <b-button 
                variant="success"
                @click="doSubmitModal('UnlockStepRequest')"
              >
                Ya, Kirim Permintaan
              </b-button>
            </div>
          </template>
        </b-card>
      </b-overlay>
    </b-modal>
    
    <b-modal 
      id="BackStepModal" 
      size="md"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
    >
      <b-overlay :show="loadingOverlay">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ modal.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="modal.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ modal.trp_triwulan }} {{ modal.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+modal.trp_bulan+'-01' | moment('MMMM') }}-{{ modal.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{modal.mo_code}} - {{ (modal.mo_level||"").toUpperCase() }} - {{modal.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p> {{modal.muk_code}} - {{ (modal.muk_level||"").toUpperCase() }} - {{modal.muk_description}}</p>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-card v-if="Object.keys(modal).length" class="p-3">
          <b-form-group label="Di Rollback ke Step:">
            <VSelect 
              v-model="input.step" 
              placeholder="-- Pilih Satu --"
              :options="mrStep" 
              :reduce="v=>v.value" 
            />
          </b-form-group>

          <template #footer>
            <div align="right">
              <b-button 
                variant="secondary"
                class="mr-2"
                @click="$bvModal.hide('BackStepModal')"
              >
                Batal
              </b-button>
              <b-button 
                variant="success"
                @click="doSubmitModal('BackStepModal')"
              >
                Kirim
              </b-button>
            </div>
          </template>
        </b-card>
      </b-overlay>
    </b-modal>

    <b-modal 
      id="ApproveModal" 
      size="md"
      ok-title="Setujui"
      cancel-title="Batal"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-header="true"
      centered
      @ok="doOKModal($event, 'ApproveModal')"
      ok-variant="info"
    >
      <b-overlay :show="loadingOverlay">
        <div class="p-3">
          <h4 class="font-weight-bold">Apa Anda Yakin Untuk Menyetujui Data ini?</h4>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal 
      id="ReviseModal" 
      size="md"
      ok-title="Ya, Revisi"
      cancel-title="Batal"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-header="true"
      centered
      @ok="doOKModal($event, 'ReviseModal')"
      ok-variant="info"
    >
      <b-overlay :show="loadingOverlay">
        <div class="p-3">
          <h4 class="font-weight-bold mb-3">Apa Anda Yakin Data Ini Membutuhkan Revisi?</h4>
          <b-form-group label-for="detailRevisi">
            <template #label>Detail Revisi <small class="text-danger">*</small></template>
            <b-textarea v-model="input.reason" id="detailRevisi" placeholder="Tulis detail revisi..."></b-textarea>
            <b-form-text>Note: Data yang membutuhkan revisi akan dikirim ke Risk Officer.</b-form-text>
          </b-form-group>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal 
      id="RejectModal" 
      size="md"
      ok-title="Ya, Tolak"
      cancel-title="Batal"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-header="true"
      centered
      @ok="doOKModal($event, 'RejectModal')"
      ok-variant="info"
    >
      <b-overlay :show="loadingOverlay">
        <div class="p-3">
          <h4 class="font-weight-bold mb-3">Apa Anda Yakin Untuk Menolak Data Ini?</h4>
          <b-form-group class="mb-0" label-for="alasanPenolakan">
            <template #label>Alasan Penolakan <small class="text-danger">*</small></template>
            <b-textarea v-model="input.reason" id="alasanPenolakan" placeholder="Tulis alasan penolakan..."></b-textarea>
            <b-form-text>Note: Data yang ditolak tidak bisa diaktifkan kembali. Anda masih bisa membuat data baru pada bulan yang sama.</b-form-text>
          </b-form-group>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

import PenaksiranStatus from '@/views/PenaksiranStatus'
import PenaksiranInherent from '@/views/PenaksiranInherent'
import PenaksiranEvidence from '@/views/PenaksiranEvidence'
import PenaksiranCurrent from '@/views/PenaksiranCurrent'
import PenaksiranTindakanMitigasi from '@/views/PenaksiranTindakanMitigasi'
import PenaksiranResidual from '@/views/PenaksiranResidual'
import PenaksiranJadwalMitigasi from '@/views/PenaksiranJadwalMitigasi'
import PenaksiranHasilMitigasi from '@/views/PenaksiranHasilMitigasi'
import PenaksiranDetail from '@/views/PenaksiranDetail'

let _ = global._
let Swal = global.Swal
export default{
  extends:GlobalVue,
  components:{
    PageTitle, CardList, VTab, VTabLink, VTabContent,
    PenaksiranStatus, PenaksiranInherent, PenaksiranEvidence,
    PenaksiranCurrent, PenaksiranTindakanMitigasi,
    PenaksiranResidual, PenaksiranJadwalMitigasi, PenaksiranHasilMitigasi, PenaksiranDetail
  },
  data(){
    return {
      tab:1,
      idKey:'trp_id',
      fields:[
        {
          key: 'number', label: '#'
        },
        {
          key: 'trp_tgl_register', label: 'ID Register Risiko', is_custom: true
        },
        {
          key: 'trp_bulan', label: 'Periode', is_custom:true
        },
        {
          key: 'mo_name', label: 'Nama Kantor', is_custom:true
        },
        {
          key: 'muk_description', label: 'Unit Kerja', is_custom: true
        },
        {
          key: 'trp_jml_risiko', label: 'Jml Risiko'
        },
        {
          key: 'status', is_custom:true
        },
        {
          key: 'penaksiran', is_custom:true, tdClass:"w_200"
        },
        {
          key:'aksi', is_custom:true, style:{width:'170px'}
        }
      ],
      periodeCurrent:{},
      modal:{},
      input:{},
      post:{},
      mrTahun:[],
      mrKantor:[],
      mrUnitKerja:[],
      mrLevel:[],
      mrStatusApprove:[
        {value:"draft", label:"Draft"},
        {value:"waiting_completed", label:"Belum Terpenuhi"},
        {value:"waiting_approve", label:"Menunggu Persetujuan"},
        {value:"revise", label:"Revisi"},
        {value:"reject", label:"Ditolak"},
        {value:"approve", label:"Disetujui"},
      ],
      mrStatusPenaksiran:[
        {value:"belum_ada", label:"Belum Ada"},
        {value:"inheren", label:"Inheren"},
        {value:"upload_evidence", label:"Upload Dokumen Pengendalian"},
        {value:"current", label:"Current"},
        {value:"tindakan_mitigasi", label:"Tindakan Mitigasi"},
        {value:"nilai_residual", label:"Nilai Residual"},
        {value:"jadwal_mitigasi", label:"Jadwal Mitigasi"},
        {value:"mitigasi", label:"Mitigasi"},
        {value:"selesai", label:"Selesai"},
      ],
    }
  },
  computed:{
    mrStep(){
      if(!Object.keys(this.modal)) return []
      let step = []
      
      if(this.modal.trp_inherent_status=='Y'){
        step.push({
          value:'inheren', label:'Inheren'
        })
      }
      if(this.modal.trp_doc_evidence_status=='Y'){
        step.push({
          value:'doc_evindence', label:'Doc. Pengendalian'
        })
      }
      if(this.modal.trp_risiko_current_plus5>0 && this.modal.trp_current_status=='Y'){
        step.push({
          value:'current', label:'Current'
        })
      }
      if(this.modal.trp_risiko_current_plus5>0){
        if(this.modal.trp_tindakan_mitigasi_status=='Y'){
          step.push({
            value:'tindakan_mitigasi', label:'Tindakan Mitigasi'
          })
        }
        if(this.modal.trp_residual_status=='Y'){
          step.push({
            value:'residual', label:'Residual'
          })
        }
      }

      return step
    },
    noFilter(){
      return !(
        this.$route.query.tahun || 
        this.$route.query.bulan || 
        this.$route.query.unit_kerja ||
        this.$route.query.kantor ||
        this.$route.query.kantor ||
        this.$route.query.level ||
        this.$route.query.status_approve ||
        this.$route.query.status_penilaian ||
        this.$route.query.search ||
        this.pageSlug
      )
    }
  },
  methods:{
    getData(){
      this.apiGet({
        callback:()=>{
          if(this.noFilter){
            this.$router.push({
              name:this.$route.name,
              query:_.assign(_.clone(this.$route.query||{}), {
                tahun:this.periodeCurrent.tahun,
                bulan:parseInt(this.periodeCurrent.bulan).toString(),
                tab:this.$route.query.tab||'bulan'
              })
            }).catch(()=>{})
          }
        }
      })

      if(!this.noFilter){
        this.$set(this,'tab', this.$route.query.tab||'bulan')
      }
    },
    canEdit(row, lock_status=['N']){
      return this.periodeCurrent.tahun == row.trp_tahun &&
              this.periodeCurrent.bulan == row.trp_bulan &&
              this.periodeCurrent.status=='ONGOING' && 
              lock_status.indexOf(row.trp_locked_periode)>-1
    },
    doOpenModal(id, data){
      this.$set(this,'input',{})
      this.$set(this,'modal', _.clone(data))
      this.$bvModal.show(id)
    },
    doOKModal(bvModalEvt, id){
      bvModalEvt.preventDefault()
      if(['ReviseModal','RejectModal'].indexOf(id)>-1 && !this.input.reason){
        return Swal.fire({
            icon: "warning",
            title: "Detail "+(id=='ReviseModal'?'Revisi':'Penolakan')+" Wajib Diisi!",
            confirmButtonText: "Mengerti"
          })
      }
      this.doSubmitModal(id)
    },
    doSubmitModal(id){
      if(['BackStepModal'].indexOf(id)>-1){
        if(!this.input.step){
          return Swal.fire({
            icon: "warning",
            title: "Rollback Step wajib diisi!",
            confirmButtonText: "Mengerti"
          })
        }
      }

      this.loadingOverlay = true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:Object.assign(this.input, {
            type:"Do"+id,
            id:this.modal[this.idKey]
          })
        },
        'POST'
      ).then((res)=>{
        this.loadingOverlay=false
        this.$bvModal.hide(id)
        this.$bvModal.hide("Detail")
        this.$bvModal.hide("UnLockStepFUModal")
        let resp = res.data
        if(Object.keys(this.$route.query).length == 1){
          this.apiGet()   
        }else {
          this.$set(this, 'filter', {})
          this.$router.push({name:this.modulePage, query:{tab:this.$route.query.tab}})
        }
        global.Swal.fire({
          title: resp.title,
          text: resp.message||"",
          icon: resp.status,
        })
      })
    },
  },
  mounted(){
    this.getData()
  },
  watch:{
    $route(){
      this.row = {}
      this.input = {}
      this.post = {}
      this.getData()
    },
    tab(v,o){
      if(!o || this.$route.params.pageSlug) return
      let q = _.clone(this.$route.query||{})
      q.tab = v
      this.$router.push({name:this.$route.name, query:q}).catch(()=>{});
    }
  }
}
</script>