<template>
<div>
  <b-card v-if="_row.trp_id" title="Penjadwalan Mitigasi">
    <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
      <form @submit.prevent="handleSubmit(doSubmitForm)">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ _row.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="_row.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p>{{_row.muk_code.toUpperCase()}} - {{_row.muk_level.toUpperCase()}} - {{_row.muk_description.toUpperCase()}}</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="card b-all shadow-none mt-3">
          <div :class="{'table-responsive':isMobile}">
            <table class="table m-b-0  table-bordered">
              <thead>
                <tr class="full-color-table full-purple-table">
                  <th rowspan="2"> # </th>
                  <th rowspan="2" style="width:200px"> Mitigasi </th>
                  <th rowspan="2" style="width:200px"> Prakiraan Biaya </th>
                  <th colspan="2" > Jadwal Mitigasi </th>
                  <th rowspan="2" > PIC </th>
                </tr>
                <tr class="full-color-table full-purple-table">
                  <th style="min-width:150px"> Tgl Mulai </th>
                  <th style="min-width:150px"> Tgl Akhir </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(v,k) in mitigasi"
                  :key="k"
                >
                  <td>{{ k+1 }}.</td>
                  <td><b>{{ v.nama }}</b></td>
                  <td>
                    <b-input-group prepend="Rp">
                      <money 
                        v-model="v.biaya" 
                        v-bind="money" 
                        class="form-control"
                      />
                    </b-input-group>
                    <VValidate 
                      :name="'Biaya ke-'+(k+1)"
                      v-model="v.biaya" 
                      rules="required" 
                    />
                  </td>
                  <td>
                    <b-form-datepicker 
                      v-model="v.jadwal_awal"
                      locale="id"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      placeholder="Select Date"
                      @input="v.jadwal_akhir=''"
                    />
                  </td>
                  <td>
                    <b-form-datepicker 
                      v-model="v.jadwal_akhir"
                      locale="id"
                      :min="v.jadwal_awal||new Date()"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      placeholder="Select Date"
                    />
                    <VValidate 
                      :name="'Jadwal ke-'+(k+1)"
                      :value="v.jadwal_awal&&v.jadwal_akhir?1:''"
                      rules="required" 
                    />
                  </td>
                  <td>
                    <VSelect
                      :options="mrPic"
                      :reduce="v2=>v2.value"
                      placeholder="-- Pilih PIC --"
                      v-model="v.pic"
                    />
                    <VValidate 
                      :name="'PIC ke-'+(k+1)"
                      v-model="v.pic"
                      rules="required"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </form>
    </ValidationObserver>

    <template #footer>
      <b-row class="mt-4">
        <b-col md=6>
          <b-button
            class="btn waves-effect btn-rounded waves-light btn-outline-dark"
            :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
          >
            Kembali
          </b-button>
        </b-col>
        <b-col md=6>
          <div align="right">
            <b-button
              class="btn btn-rounded  btn-info"
              @click="doSubmitForm()"
            >
              Konfirmasi Penaksiran
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import {Money} from 'v-money'

let _ = global._
let Swal = global.Swal
export default {
  extends:GlobalVue,
  components:{Money},
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision:0,
        masked: false
      }
    }
  },
  computed:{
    mitigasi(){
      return this._post?.mitigasi||[]
    },
    mrPic(){
      return this._post?.mrPic||[]
    }
  },
  methods:{
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'DoJadwalMitigasi',
          id: this.pageId,
          mitigasi:_.clone(this.mitigasi)
        }, 
        (type, resp)=>{
          if(type=='success'){
            Swal.fire({
              title: "Mitigasi Berhasil Dijadwalkan!",
              icon: "success",
              confirmButtonText: 'Kembali ke List',
              reverseButtons:true,
              allowEscapeKey:false,
              allowEnterKey:false,
              allowOutsideClick:false
            }).then((result) => {
              if(result.value){
                this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
              }
            })
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    }
  }
}
</script>