<template>
<div>
  <b-card v-if="_row.trp_id" title="Isi Hasil Mitigasi">
    <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
      <form @submit.prevent="handleSubmit(doSubmitForm)">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ _row.trp_register_code }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="_row.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p>{{_row.muk_code.toUpperCase()}} - {{_row.muk_level.toUpperCase()}} - {{_row.muk_description.toUpperCase()}}</p>
              </div>
            </b-col>
          </b-row>
        </div>

         <div class="card b-all shadow-none mt-3 mb-1">
          <div class="inbox-center table-responsive">
            <table class="table m-b-0 table-striped table-hover">
              <thead>
                <tr class=" text-center  full-color-table full-purple-table">
                  <th> # </th>
                  <th> Nama Mitigasi </th>
                  <th> Perkiraan Biaya (Rp) </th>
                  <th> Perkiraan Jadwal </th>
                  <th> PIC </th>
                  <th style="min-width:250px"> Doc. Mitigasi </th>
                  <th style="min-width:250px"> Hasil </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(v,k) in mitigasi"
                  :key="k"
                  class="unread"
                >
                  <td>{{k+1}}</td>
                  <td>{{ v.nama }}</td>
                  <td>Rp {{ v.biaya.format() }}</td>
                  <td>{{ v.jadwal_awal | moment("DD MMMM YYYY") }} - {{ v.jadwal_akhir | moment("DD MMMM YYYY") }}</td>
                  <td>{{v.pic}}</td>
                  <td style="text-align:center">
                    <template v-if="v.doc">
                      <ImagePreview 
                        :src="uploader(v.doc)"
                      >
                        <a href="javascript:;" class="txt_link_download mb-3 d-inline-block"> 
                          <i class="far fa-file-pdf"></i> Tersedia
                        </a>
                      </ImagePreview>
                      <br />
                      <Uploader 
                        v-model="v.doc" 
                        :no-help="true"
                        :no-preview="true"
                        type="document_mitigasi" 
                        label="Dokumen"
                      >
                        <a 
                          slot="btnupload" 
                          href="javascript:;" 
                          class="txt_link_download ml-2"
                        > 
                          <b-icon icon="cloud-upload" aria-hidden="true"></b-icon> Upload Ulang
                        </a>
                        <a 
                          slot="btnloading" 
                          href="javascript:;" 
                          class="txt_link_download text-warning border-warning ml-2"
                        > 
                          Uploading...
                        </a>
                      </Uploader>
                    </template>
                    <template v-else>
                      <Uploader 
                        v-model="v.doc" 
                        :no-help="true"
                        :no-preview="true"
                        type="document_mitigasi" 
                        label="Dokumen"
                      />
                      <div class="wrap_info">
                        <center><small>Format: {{$root.app.uploader.document.rule_type.toUpperCase().split(',').join(', ')}}</small></center>
                        <center><small>
                          Maximal ukuran file {{$root.app.uploader["max_image_size"].bytesToSize()}}
                        </small></center>
                      </div>
                    </template>
                    <VValidate 
                      :name="'Hasil ke-'+(k+1)"
                      v-model="v.doc" 
                      rules="required" 
                    />
                  </td>
                  <td>
                    <textarea 
                      v-model="v.hasil" 
                      class="form-control" 
                      placeholder="Isi hasil disini"
                      rows="5" 
                    />
                    <VValidate 
                      :name="'Dokumen ke-'+(k+1)"
                      v-model="v.hasil" 
                      rules="required" 
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
         </div>
      </form>
    </ValidationObserver>

    <template #footer>
      <b-row class="mt-2">
        <b-col md=6>
          <b-button
            class="btn waves-effect btn-rounded waves-light btn-outline-dark"
            :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
          >
            Kembali
          </b-button>
        </b-col>
        <b-col md=6>
          <div align="right">
            <b-button
              class="btn btn-rounded  btn-info"
              @click="doSubmitForm()"
            >
              Konfirmasi Penaksiran
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

let Swal = global.Swal
export default {
  extends:GlobalVue,
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
    }
  },
  computed:{
    mitigasi(){
      return this._post?.mitigasi
    }
  },
  methods:{
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'DoHasilMitigasi',
          id: this.pageId,
          mitigasi:this.mitigasi
        }, 
        (type, resp)=>{
          if(type=='success'){
            Swal.fire({
              title: "Hasil Mitigasi Berhasil Tersimpan!",
              icon: "success",
              confirmButtonText: 'Kembali ke List',
              reverseButtons:true,
              allowEscapeKey:false,
              allowEnterKey:false,
              allowOutsideClick:false
            }).then((result) => {
              if(result.value){
                this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
              }
            })
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    }
  }
}
</script>