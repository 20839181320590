var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm._row.trp_approve_status=='Y')?[(['D','P'].indexOf(_vm._row.trp_inherent_status)>-1)?_c('p',{staticClass:"status_penilaian text-success f-600"},[_vm._v(" Inheren "),(
          _vm.canEdit && 
          !_vm.moduleRole('ViewOnly') && 
          _vm.moduleRole('isPenaksir') &&
          !(_vm._row.PenaksiranDid||false)
        )?_c('router-link',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:'inheren', pageId:_vm._row.trp_id}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Lakukan Penilaian Inheren"}},[_c('i',{staticClass:"icon-note"})])]):_vm._e()],1):(_vm._row.trp_inherent_status=='Y' && _vm._row.trp_doc_evidence_status=='N')?_c('p',{staticClass:"status_penilaian text-success f-600"},[_vm._v(" Dok Pengendalian "),(_vm.canEdit && !_vm.moduleRole('ViewOnly') && _vm.moduleRole('isRiskOfficer'))?_c('router-link',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:'upload-evidence', pageId:_vm._row.trp_id}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Upload Dokumen Pengendalian"}},[_c('i',{staticClass:"icon-cloud-upload"})])]):_vm._e()],1):(
        ['D','P'].indexOf(_vm._row.trp_current_status)>-1 && _vm._row.trp_doc_evidence_status=='Y'
      )?_c('p',{staticClass:"status_penilaian text-info f-600"},[_vm._v(" Current "),(
          _vm.canEdit && 
          !_vm.moduleRole('ViewOnly') && 
          _vm.moduleRole('isPenaksir') &&
          !(_vm._row.PenaksiranDid||false)
        )?_c('router-link',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:'current', pageId:_vm._row.trp_id}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Isi Nilai Current"}},[_c('i',{staticClass:"icon-note"})])]):_vm._e()],1):(
        _vm._row.trp_tindakan_mitigasi_status=='N'
      )?_c('p',{staticClass:"status_penilaian text-warning f-600"},[_vm._v(" Tindakan Mitigasi "),(_vm.canEdit && !_vm.moduleRole('ViewOnly') && _vm.moduleRole('isRiskOfficer'))?_c('router-link',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:'tindakan-mitigasi', pageId:_vm._row.trp_id}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Buat Tindakan Mitigasi"}},[_c('i',{staticClass:"icon-note"})])]):_vm._e()],1):(
        ['N','P'].indexOf(_vm._row.trp_residual_status)>-1
      )?_c('p',{staticClass:"status_penilaian text-danger f-600"},[_vm._v(" Residual "),(
          _vm.canEdit && 
          !_vm.moduleRole('ViewOnly') && 
          _vm.moduleRole('isPenaksir') &&
          !(_vm._row.PenaksiranDid||false)
        )?_c('router-link',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:'residual', pageId:_vm._row.trp_id}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Isi Nilai Residual"}},[_c('i',{staticClass:"icon-note"})])]):_vm._e()],1):(
        _vm._row.trp_jadwal_mitigasi_status=='N'
      )?_c('p',{staticClass:"status_penilaian text-danger f-600"},[_vm._v(" Jadwal Mitigasi "),(_vm.canEdit && !_vm.moduleRole('ViewOnly') && _vm.moduleRole('isRiskOfficer'))?_c('router-link',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:'jadwal-mitigasi', pageId:_vm._row.trp_id}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Buat Jadwal Mitigasi"}},[_c('i',{staticClass:"icon-note"})])]):_vm._e()],1):(
        _vm._row.trp_selesai_date
      )?_c('p',{staticClass:"status_penilaian text-purple f-600"},[_vm._v(" Selesai ")]):_vm._e()]:_c('p',{staticClass:"status_penilaian f-600"},[_vm._v(" Belum Ada ")]),(_vm._row.trp_locked_periode=='Y')?_c('p',{staticClass:"status_penilaian f-600 text-info"},[_vm._v(" Locked ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }