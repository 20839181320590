<template>
<b-card v-if="_row.trp_id" title="Isi Inheren">
  <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
    <form @submit.prevent="handleSubmit(doSubmitForm)">
      <div class="wrap_info_preview">
        <b-row class="mb-3">
          <b-col md=5>
            <div class="wrap_preview_prof">
              <h3>ID Risiko</h3>
              <p>{{ _row.trp_register_code }}</p>
            </div>
          </b-col>
          <b-col md=5>
            <div class="wrap_preview_prof">
              <h3>Periode</h3>
              <p v-if="_row.trp_is_periode=='triwulan'"> 
                Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
              </p>
              <p v-else>
                {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md=5>
            <div class="wrap_preview_prof">
              <h3>Kantor</h3>
              <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
            </div>
          </b-col>
          <b-col md=5>
            <div class="wrap_preview_prof">
              <h3>Unit Kerja</h3>
              <p>{{_row.muk_code.toUpperCase()}} - {{_row.muk_level.toUpperCase()}} - {{_row.muk_description.toUpperCase()}}</p>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :fields="fields"
        :items="inherent"
        small
        show-empty
        class="m-b-0 mt-2 table-hover table-bordered"
        bordered
        responsive
      >
        <template #empty>
          <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
        </template>
        <template #thead-top>
          <b-tr>
            <b-th 
              colspan="2"
              class="full-color-table full-purple-table"
            />
            <b-th 
              colspan="3"
              class="text-center full-color-table full-danger-table"
            >
              <center>Inheren</center>
            </b-th>
          </b-tr>
        </template>
        <template #cell(number)="data">
          {{data.index+1}}
        </template>
        <template #cell(risiko_name)="data">
          <p class="mb-0">{{data.value}}</p>
          <ul>
            <li>
              Risiko Sebab 
              <a class="link_cta_add" href="javascript:;" v-b-tooltip.hover.bottom="'Lihat Detail'" @click="doOpenModal(data.item, 'sebab')">
                {{ data.item.sebab.length }} <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
            <li>
              Risiko Dampak 
              <a class="link_cta_add" href="javascript:;" v-b-tooltip.hover.bottom="'Lihat Detail'" @click="doOpenModal(data.item, 'dampak')">
                {{ data.item.dampak.length }} <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
            <li>
              Risiko Pengendalian 
              <a class="link_cta_add" href="javascript:;" v-b-tooltip.hover.bottom="'Lihat Detail'" @click="doOpenModal(data.item, 'kendali')">
                {{ data.item.kendali.length }} <i class="fas fa-external-link-alt"></i>
              </a>
            </li>
          </ul>
        </template>
        <template #cell(k)="data">
          <VSelect
            v-model="data.item.k" 
            placeholder="--Pilih Nilai--"
            :options="Config.master.OptionInheren"
            :clearable="false" 
            :reduce="v=>v.value" 
          />
          <VValidate 
            :name="'K ke-'+(data.index+1)"
            v-model="data.item.k" 
            :rules="{required:1, regex: /^[0-9]+$/}" 
          />
        </template>
        <template #cell(d)="data">
          <VSelect
            v-model="data.item.d" 
            placeholder="--Pilih Nilai--"
            :options="Config.master.OptionInheren" 
            :clearable="false" 
            :reduce="v=>v.value" 
          />
          <VValidate 
            :name="'D ke-'+(data.index+1)"
            v-model="data.item.d" 
            :rules="{required:1, regex: /^[0-9]+$/}" 
          />
        </template>
        <template #cell(kd)="data">
          <b-input
            :value="(data.item.k&&data.item.d?data.item.k*data.item.d:'')"
            readonly
          />
        </template>
      </b-table>

    </form>
  </ValidationObserver>

  <template #footer>
    <b-row class="mt-4">
      <b-col md=6>
        <b-button
          class="btn waves-effect btn-rounded waves-light btn-outline-dark"
          :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
        >
          Kembali
        </b-button>
      </b-col>
      <b-col md=6>
        <div align="right">
          <b-button
            class="btn btn-rounded  btn-info"
            @click="doSubmitForm()"
          >
            Konfirmasi Penaksiran
          </b-button>
        </div>
      </b-col>
    </b-row>
  </template>

  <b-modal 
    id="RisikoModal" 
    :title="'Data Pustaka - '+modal.risiko_name"
    size="xl"
    hide-footer
  >
    <VTab :justify="true">
      <template slot="link">
        <VTabLink :step.sync="tabRisiko" option="sebab">Sebab</VTabLink>
        <VTabLink :step.sync="tabRisiko" option="dampak">Dampak</VTabLink>
        <VTabLink :step.sync="tabRisiko" option="kendali">Pengendalian</VTabLink>
      </template>
      <template slot="content">
        <VTabContent :step.sync="tabRisiko" :option="tabRisiko">
          <div :class="{'table-responsive':isMobile, 'inbox-center mt-2': 1}">
            <table class="table m-b-0 table-bordered">
              <thead>
                <tr class="full-color-table full-purple-table" >
                  <th> # </th>
                  <th>Kategori</th>
                  <th>Deskripsi</th>
                  <th v-if="tabRisiko=='kendali'">Terkait</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(v,k) in modal[tabRisiko]"
                  :key="k"
                >
                  <td class="text-center">{{k + 1}}</td>
                  <td>{{ v.kategori }}</td>
                  <td>{{ v.nama }}</td>
                  <td v-if="tabRisiko=='kendali'">
                    {{ v.terkait=='sebab'?'kemungkinan':(v.terkait||'-') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </VTabContent>
      </template>
    </VTab>
  </b-modal>
</b-card>
</template>

<script>
import GlobalVue from '@/libs/Global'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let Swal = global.Swal
export default {
  extends:GlobalVue,
  components:{
    VTab, VTabLink, VTabContent
  },
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      modal:{},
      tabRisiko:'',
      fields:[
        {key:'number', label:'#', thClass:'full-color-table full-purple-table v_middle'},
        {key:'risiko_name', label:'Nama Risiko', thClass:'full-color-table full-purple-table v_middle'},
        {key:'k', thClass:'text-center full-color-table w_200 full-danger-table'},
        {key:'d', thClass:'text-center full-color-table w_200 full-danger-table'},
        {key:'kd', label:'K x D', thClass:'text-center full-color-table full-danger-table w_200' },
      ]
    }
  },
  computed:{
    inherent(){
      return this._post?.inherent||[]
    }
  },
  methods:{
    doOpenModal(data, tab){
      this.$set(this,'modal', data)
      this.$set(this,'tabRisiko', tab)
      this.$bvModal.show('RisikoModal')
    },
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'DoInherent',
          id: this.pageId,
          inherent:_.clone(this.inherent)
        }, 
        (type, resp)=>{
          if(type=='success'){
            
            if(resp.inheren_status=='P'){
              // NOT YET COMPLETED
              Swal.fire({
                title: "Nilai Inheren Berhasil Diisi!",
                icon: "success",
                text:'Data dilanjutkan ke Risk Officer untuk upload dokumen pengendalian',
                confirmButtonText: 'Kembali ke List',
                reverseButtons:true,
                allowEscapeKey:false,
                allowEnterKey:false,
                allowOutsideClick:false
              }).then((result) => {
                if(result.value){
                  this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
                }
              })
            }else{
              /** TRIWULAN */
              Swal.fire({
                title: "Nilai Inheren Berhasil Diisi!",
                icon: "success",
                text:'Data dilanjutkan ke Risk Officer untuk upload dokumen pengendalian',
                confirmButtonText: 'Kembali ke List',
                reverseButtons:true,
                allowEscapeKey:false,
                allowEnterKey:false,
                allowOutsideClick:false
              }).then((result) => {
                if(result.value){
                  this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
                }
              })
            }

          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    }
  }
}
</script>