<template>
<b-card v-if="_row.trp_id" title="Upload Dokumen Pengendalian">
  <div class="wrap_info_preview">
    <b-row class="mb-3">
      <b-col md=5>
        <div class="wrap_preview_prof">
          <h3>ID Risiko</h3>
          <p>{{ _row.trp_register_code }}</p>
        </div>
      </b-col>
      <b-col md=5>
        <div class="wrap_preview_prof">
          <h3>Periode</h3>
          <p v-if="_row.trp_is_periode=='triwulan'"> 
            Triwulan ke-{{ _row.trp_triwulan }} {{ _row.trp_tahun }}
          </p>
          <p v-else>
            {{ '2021-'+_row.trp_bulan+'-01' | moment('MMMM') }}-{{ _row.trp_tahun }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md=5>
        <div class="wrap_preview_prof">
          <h3>Kantor</h3>
          <p> {{_row.mo_code}} - {{ (_row.mo_level||"").toUpperCase() }} - {{_row.mo_name}}</p>
        </div>
      </b-col>
      <b-col md=5>
        <div class="wrap_preview_prof">
          <h3>Unit Kerja</h3>
          <p> {{_row.muk_code}} - {{ (_row.muk_level||"").toUpperCase() }} - {{_row.muk_description}}</p>
        </div>
      </b-col>
    </b-row>
    <!-- <b-row class="mt-3">
      <b-col md=5>
        <div class="wrap_preview_prof">
          <h3>Penaksir</h3>
          <p> {{penaksir.fullname}}</p>
        </div>
      </b-col>
      <b-col md=5>
        <div class="wrap_preview_prof">
          <h3>Inheren Diisi Tanggal</h3>
          <p> {{penaksir.create_date | moment('DD MMMM YYYY')}}</p>
        </div>
      </b-col>
    </b-row> -->
  </div>

  <b-table
    :fields="fields"
    :items="risiko"
    small
    show-empty
    class="m-b-0 mt-2 table-hover table-bordered"
    bordered
    responsive
  >
    <template #empty>
      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
    </template>
    <template #thead-top>
      <b-tr>
        <b-th 
          colspan="2"
          class="full-color-table full-purple-table"
        />
        <b-th 
          colspan="3"
          class="text-center full-color-table full-danger-table"
        >
          <center>Inheren</center>
        </b-th>
        <b-th class="full-color-table full-info-table"></b-th>
        <b-th class="full-color-table full-warning-table"></b-th>
      </b-tr>
    </template>
    <template #cell(number)="data">
      {{data.index+1}}
    </template>
    <template #cell(doc_evidence)="data">
      <template v-if="data.item.doc_evidence">
        <ImagePreview 
          :src="uploader(data.item.doc_evidence)"
        >
          <a href="javascript:;" class="txt_link_download"> 
            <i class="far fa-file-pdf"></i> Tersedia
          </a>
        </ImagePreview>
        <Uploader 
          v-if="_row.trp_doc_evidence_status!='Y'"
          v-model="data.item.doc_evidence" 
          :no-help="true"
          :no-preview="true"
          type="document_evidence" 
          label="Dokumen"
        >
          <a 
            slot="btnupload" 
            href="javascript:;" 
            class="txt_link_download ml-2"
          > 
            <b-icon icon="cloud-upload" aria-hidden="true"></b-icon> Upload Ulang
          </a>
          <a 
            slot="btnloading" 
            href="javascript:;" 
            class="txt_link_download text-warning border-warning ml-2"
          > 
            Uploading...
          </a>
        </Uploader>
      </template>
      <Uploader 
        v-else
        v-model="data.item.doc_evidence" 
        :no-help="true"
        :no-preview="true"
        type="document_evidence" 
        label="Dokumen"
      />
    </template>
    <template #cell(catatan_pengendalian)="data">
      <b-textarea 
        v-model="data.item.catatan_pengendalian" 
        class="form-control" 
        placeholder="Catatan"
        rows="3"
        @input="checkValidasiCatatan(data.item.catatan_pengendalian, data.index)"
      />
      <small class="help-block d-block text-secondary" style="color:black !important">minimal 20 karakter &amp; maksimal 250 karakter.</small>
      <VValidate 
        :name="'Catatan ke-'+(data.index+1)"
        v-model="validasiCatatan[data.index]" 
        rules="required" 
        message="Jumlah Karakter Tidak Sesuai"
      />
    </template>
  </b-table>

  <template #footer>
    <b-row class="mt-4">
      <b-col md=6>
        <b-button
          class="btn waves-effect btn-rounded waves-light btn-outline-dark"
          :to="{name:$route.name, query:{tab: _row.trp_is_periode}}"
        >
          Kembali
        </b-button>
      </b-col>
      <b-col md=6>
        <div class="d-flex float-right">
          <div v-if="_row.trp_is_periode=='triwulan'" align="right">
            <h6 v-if="docNotYetUpload.length" class="text-semibold text-danger mr-2 mt-2">
              {{docNotYetUpload.length}} dokumen belum diupload
            </h6>
            <h6 v-else class="text-semibold text-success mr-2 mt-2">
              Semua dokumen sudah diupload!
            </h6>
          </div>
          <div align="right">
            <b-button
              class="btn btn-rounded  btn-info"
              @click="doSubmitForm()"
            >
              Konfirmasi Penaksiran
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </template>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global'
let _ = global._
let Swal = global.Swal
export default {
  extends:GlobalVue,
  props:{
    _row:Object,
    _post:Object,
  },
  data(){
    return {
      fields:[
        {key:'number', label:'#', thClass:'full-color-table full-purple-table v_middle'},
        {key:'risiko_name', label:'Nama Risiko', thClass:'full-color-table full-purple-table v_middle'},
        {key:'k', tdClass:'text-center', thClass:'text-center full-color-table w_100 full-danger-table'},
        {key:'d', tdClass:'text-center', thClass:'text-center full-color-table w_100 full-danger-table'},
        {key:'kd', label:'K x D', tdClass:'text-center', thClass:'text-center full-color-table full-danger-table w_100' },
        {key:'doc_evidence', label:'Dokumen Pengendalian', tdClass:'text-center', thClass:'text-center full-color-table full-info-table w_250' },
        {key:'catatan_pengendalian', label:'Catatan', tdClass:'text-center', thClass:'text-center full-color-table full-warning-table w_250' }
      ]
    }
  },
  computed:{
    risiko(){
      return this._post?.risiko||[]
    },
    /* penaksir(){
      return this._post?.penaksir||{}
    }, */
    docNotYetUpload(){
      return _.filter(this.risiko, (v)=>{
        return !v.doc_evidence
      })
    },
    docUploadedCatatanEmpty(){
      return _.filter(this.risiko, (v)=>{
        return v.doc_evidence && !v.catatan_pengendalian
      })
    },
    validasiCatatan(){
      return Array(this.risiko.length).fill(' ');
    }
  },
  methods:{
    checkValidasiCatatan(newVal, index){
      const length = newVal.replace(/(\s|\n)/g, "").length
      if(length >= 20 && length <= 250){
        this.validasiCatatan[index] = 'true'
      }else {
        this.validasiCatatan[index] = null
      }
    },
    doSubmitForm(){
      /* if(this._row.trp_is_periode=='triwulan'){
        if(this.docNotYetUpload.length >= this.risiko.length){
          return Swal.fire({
            icon:'warning',
            title:'Belum Ada Dokumen Yang Diupload!'
          })
        }
        if(this.docNotYetUpload.length){
          return Swal.fire({
            icon:'warning',
            title:'Semua Dokumen Wajib Diupload!'
          })
        }
      }  */
      if(this.docUploadedCatatanEmpty.length){
        return Swal.fire({
          icon:'warning',
          title:'Terdapat Catatan yang Belum Terisi!'
        })
      }
      this.loadingOverlay=true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:{
            type:'DoUploadEvidence',
            id: this.pageId,
            risiko:this.risiko
          }
        },
        'POST'
      ).then((resp)=>{
        this.loadingOverlay=false
        if(resp.data.all_uploaded=='Y'){
          Swal.fire({
            title: "Berhasil Upload Dokumen Pengendalian!",
            icon: "success",
            text:'Data dikirim ke Penaksir untuk melanjutkan mengisi nilai current',
            confirmButtonText: 'Kembali ke List',
            reverseButtons:true,
            allowEscapeKey:false,
            allowEnterKey:false,
            allowOutsideClick:false
          }).then((result) => {
            if(result.value){
              this.$router.push({name:this.modulePage, query:{tab:this._row.trp_is_periode}})
            }
          })
        }else{
          Swal.fire({
            title: "Berhasil Upload Dokumen Pengendalian!",
            icon: "success",
            confirmButtonText: 'Mengerti',
            reverseButtons:true,
            allowEscapeKey:false,
            allowEnterKey:false,
            allowOutsideClick:false
          }).then(() => { })
        }
      })
    }
  }
}
</script>